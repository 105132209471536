import React from "react";
import { useStoreState } from "@/hooks/storeHooks";
import Image from "next/image";
interface RestrictedProps {
  roleName: string;
  children: React.ReactNode;
  invisible?: boolean;
}

export default function Restricted({ roleName, children, invisible = true }: RestrictedProps) {
  const tenant = useStoreState((s) => s.adminTenant);

  if (hasPermission(roleName, tenant)) {
    return <>{children}</>;
  }

  if (invisible) {
    return <></>;
  }

  return (
    <div className="flex flex-col space-y-4">
      <Image src={"/undraw-illustrations/restricted.svg"} alt="Access denied" width={200} height={200} />
      <div className="max-w-md">
        <p className="font-bold text-lg">Access denied</p>
        <p className="text-muted-foreground">
          {"You don't have the permissions to access this page. Ask your shop admin to give you this permission."}
        </p>
      </div>
    </div>
  );
}

export function hasPermission(roleName: string, tenant?: any) {
  return tenant?.roles?.includes(roleName) || tenant?.roles?.includes("*");
}
