import { createClient } from "../lib/supabase/client";
import { Tables } from "../types";

export function getTenantLogoUrl(tenant: Tables<"tenants">, tenantLogoUrl?: string) {
  return createClient()
    .storage.from("tenants")
    .getPublicUrl(tenant.id + "/" + (tenantLogoUrl || tenant.logo_from_storage)).data.publicUrl;
}

export function getUserAvatar(userUuid: string, userAvatarUrl: string) {
  return createClient()
    .storage.from("avatars")
    .getPublicUrl(userUuid + "/" + userAvatarUrl).data.publicUrl;
}

export function getPodStoreLogo(storeUuid: string, url: string) {
  return createClient()
    .storage.from("pod_stores")
    .getPublicUrl(storeUuid + "/" + url).data.publicUrl;
}

export function getProductImageUrl(tenantUuid: string, productUuid: string, productImageUrl: string) {
  if (productImageUrl.startsWith("http")) return productImageUrl;

  return createClient()
    .storage.from("products")
    .getPublicUrl(tenantUuid + "/" + productUuid + "/" + productImageUrl).data.publicUrl;
}

export function getTenantFaviconUrl(tenant: Tables<"tenants">, overrideUuid?: string) {
  if (!tenant) return "";
  const uuid = overrideUuid || tenant.favicon_url;
  if (!uuid) return "";
  return createClient()
    .storage.from("tenants")
    .getPublicUrl(tenant.id + "/" + uuid).data.publicUrl;
}
