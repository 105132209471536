export function n(n: number, params: any = {}) {
  return Intl.NumberFormat("en-US", params).format(n);
}

export const formatCurrency = (value: number, currency: string = "USD", decimals: number = 2): string => {
  if (isNaN(value)) {
    return "";
  }

  const formattedNumber = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value);

  if (formattedNumber == "NaN") {
    return "";
  }

  return formattedNumber;
};
