"use client";

import "../styles/globals.css";
import { StoreProvider } from "easy-peasy";
import ErrorToaster from "./error-toaster";
import store from "./store";
import InitialStore from "@/components/intial-store/InitialStore";
import { HighlightInit } from "@highlight-run/next/client";


export default function LayoutContainer({ children }: { children: React.ReactNode }) {
  return (
    <>
      {process.env.NEXT_PUBLIC_ENV === "production" && (
        <HighlightInit
          projectId={process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
          environment={process.env.NEXT_PUBLIC_ENV}
          serviceName="manufactor"
          tracingOrigins
          networkRecording={{
            enabled: true,
            recordHeadersAndBody: true,
            urlBlocklist: [],
          }}
        />
      )}

   
        <StoreProvider store={store}>
          <InitialStore />
         
            {children}

            <ErrorToaster />
        </StoreProvider>
    </>
  );
}
