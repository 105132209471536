import {
  cloneDeep,
  curry,
  flatMap,
  flow,
  groupBy,
  includes,
  map,
  omit,
  partial,
  property,
  propertyOf,
  set,
} from "lodash";

export function isIn(array: (string | number)[]) {
  return partial(includes, array);
}

export function propertyIn(path: string, array: (string | number)[]) {
  return flow(property(path), isIn(array));
}

export const unwind = curry(function unwind<T extends object>(path: string, data: T[]) {
  data = cloneDeep(data);

  const unwinded = flatMap(data, function mapper(entry) {
    return map(propertyOf(entry)(path), function mapper(subEntry) {
      entry = cloneDeep(entry);
      return set(entry, path, subEntry);
    });
  });

  return unwinded;
});

// Reverse the effect of unwind function
export const wind = curry(function wind<T extends object>(baseProperty: string, propertyToWind: string, data: T[]) {
  data = cloneDeep(data);

  // Group entries by base property e.g. uuid
  const grouped = groupBy(data, baseProperty);

  // Reconstruct the original structure
  const winded = map(grouped, function mapper(entries) {
    // const baseEntry = omit(entries[0], propertyToWind) as T;

    // Extract the values for the unwound property
    const nestedValues = map(entries, propertyToWind);

    // Set the path property back to the grouped array of values
    return set(entries[0], propertyToWind, nestedValues) as T;
  });

  return winded;
});
