import { ColorCustomization, LabelCustomization } from "@/types/supabase-custom/Customization";
import { createClient } from "@/lib/supabase/client";
import { Tables } from "@/types";
import { JoinedProduct } from "@/types/supabase-custom/JoinedProduct";
import { EmbellishmentType } from "@/types/enums/embellishmentType.enum";
import {
  booleanTriggerOperators,
  ColorSelectionTechnique,
  DecidingProperty,
  Impact,
  JoinedPriceModifier,
  JoinedPriceModifierAssignment,
  numberTriggerOperators,
  PriceChangeResult,
  PriceImpact,
  PriceModifierComponent,
  PriceModifierScope,
  PriceModifierStatus,
  PriceModifierType,
  rushOrdersOperators,
  Trigger,
} from "@/types/price-modifiers/priceModifiers";
import { getDigitalPrintArtworksCumulativeHeight } from "./artworkUtils";
import { CanvaOrientations } from "@/app/studio/(public)/products/[productHandle]/components/reducers/canvaOrientationsReducer";
import { JoinedLineItem } from "@/types/supabase-custom/JoinedLineItem";
import { SupabaseUtils } from "../supabase/supabaseUtils";
import { cloneDeep, find, matchesProperty, sumBy } from "lodash";
import { err } from "neverthrow";
import { PriceThreshold } from "./productUtils";
import { JoinedCart } from "@/types/supabase-custom/JoinedCart";
import { calculateCartSubtotal, calculateCartTotalPrice } from "./cartUtils";

export async function getPriceModifiersForTenant(
  tenantId: string,
  isValid: boolean
): Promise<JoinedPriceModifier[] | null> {
  const client = createClient();

  const query = await client
    .from("price_modifiers_assignments")
    .select(
      `
      *,
      price_modifiers (
        *,
        price_modifiers_components ( * )
      )
    `
    )
    .eq("tenant_id", tenantId)
    .is("deleted_at", null);

  if (query.error) {
    // console.error("Error fetching price modifiers:", query.error);
    return null;
  }

  const priceModifiers = query.data.map((assignment) => ({
    ...assignment.price_modifiers,
    assignment_id: assignment.id,
    product_id: assignment.product_id,
  }));
  const validPriceModifiers = priceModifiers.filter((pm) => pm !== null);
  if (isValid) {
    return validPriceModifiers.filter((pm) => pm.status !== "disabled" && pm.status !== "draft" && !pm.deleted_at);
  }
  return validPriceModifiers;
}

export async function getPriceModifiersFromIds(tenantId: string, priceModifierIds: number[]) {
  const client = createClient();

  const { data, error } = await client
    .from("price_modifiers")
    .select(
      `
            *,
            price_modifiers_components ( * )
        `
    )
    .is("deleted_at", null)
    .in("id", priceModifierIds);

  if (error) {
    // console.error("Error fetching price modifiers from ids:", error);
    return [];
  }

  return data;
}

export function getPriceModifiersForProduct(product: JoinedProduct, priceModifiers: JoinedPriceModifier[]) {
  return priceModifiers.filter((priceModifier) => {
    const isLineItemScope = priceModifier.scope === PriceModifierScope.line_item;

    const isAssignedToProduct =
      priceModifier.product_id === null ||
      priceModifier.product_id === undefined ||
      priceModifier.product_id === product.id;

    return isLineItemScope && isAssignedToProduct;
  });
}

export async function getProductsForPriceModifier(tenantId: string, priceModifierId: number): Promise<JoinedProduct[]> {
  const data = await getPriceModifiersForTenant(tenantId, false);
  const supabaseUtils = new SupabaseUtils(createClient());

  const productIds =
    data
      ?.filter((priceModifier) => priceModifier.id === priceModifierId)
      .flatMap((priceModifier) => priceModifier.product_id)
      .filter((productId): productId is number => productId !== null && productId !== undefined) || [];

  const productsResults = await Promise.all(
    productIds.map((productId) =>
      supabaseUtils.getProductById<JoinedProduct>(
        productId,
        "*, price_lists ( *, price_lists_components ( * ) )",
        tenantId
      )
    )
  );

  const products = productsResults
    .map((result) => {
      if (result.isErr()) {
        // console.error("Error fetching product:", result.error);
        return null;
      }
      return result.value;
    })
    .filter((product): product is JoinedProduct => product !== null);

  return products;
}

export function calculateTotalArtworkSurfaceArea(
  configuration: any,
  embellishmentType: string,
  colorSelectionTechnique?: string,
  side?: any
): number {
  let totalSurfaceArea = 0;

  const customizations = configuration.customizations;

  if (side) {
    const sideData = side;
    const artworks = sideData.artworks;

    if (Array.isArray(artworks)) {
      artworks.forEach((artwork: any) => {
        if (
          artwork.dimensions &&
          typeof artwork.dimensions.width === "number" &&
          typeof artwork.dimensions.height === "number" &&
          artwork.metadata &&
          artwork.metadata.embellishmentType === embellishmentType &&
          (!colorSelectionTechnique || artwork.metadata.colorSelectionTechnique === colorSelectionTechnique)
        ) {
          // Calculate the surface area of the artwork
          const area = artwork.dimensions.width * artwork.dimensions.height;
          totalSurfaceArea += area;
        }
      });
    }
  }
  // console.log("configuration$$$$$$$$$$$$", configuration);
  // console.log("side$$$$$$$$$$$$", side);
  // console.log("💰 totalSurfaceArea", totalSurfaceArea);
  return totalSurfaceArea;
}

export function calculateSmallestDimension(
  configuration: any,

  embellishmentType: string,
  colorSelectionTechnique?: string,
  side?: any
): number | null {
  let smallestDimension: number | null = null;

  const customizations = configuration.customizations;
  // console.log("@@@@@@@@@@configuration ", configuration);
  if (side) {
    const sideData = side;
    const artworks = sideData.artworks;
    // console.log("@@@@@@@@@@artworks ", artworks);
    // console.log("@@@@@@@@@@sideData ", sideData);
    if (Array.isArray(artworks)) {
      artworks.forEach((artwork: any) => {
        if (
          artwork.dimensions &&
          typeof artwork.dimensions.width === "number" &&
          typeof artwork.dimensions.height === "number" &&
          artwork.metadata &&
          artwork.metadata.embellishmentType === embellishmentType &&
          (!colorSelectionTechnique || artwork.metadata.colorSelectionTechnique === colorSelectionTechnique)
        ) {
          // Get the smaller dimension of the artwork
          const smallerDimension = Math.min(artwork.dimensions.width, artwork.dimensions.height);
          // console.log("artwork.dimensions.width", artwork.dimensions.width);
          // Update the smallestDimension if it's null or if the current smallerDimension is smaller
          if (smallestDimension === null || smallerDimension < smallestDimension) {
            smallestDimension = smallerDimension;
          }
        }
      });
    }
  }
  // console.log("@@@@@@@@@@smallestDimension", smallestDimension);
  return smallestDimension;
}

export function checkIfHasEmbellishmentType(configuration: any, embellishmentType: string): boolean {
  const customizations = configuration.customizations;

  for (const side in customizations) {
    if (customizations.hasOwnProperty(side)) {
      const sideData = customizations[side];
      const artworks = sideData.artworks;

      if (Array.isArray(artworks)) {
        const hasEmbellishment = artworks.some(
          (artwork: any) =>
            artwork.metadata && artwork.metadata.imageUrl && artwork.metadata.embellishmentType === embellishmentType
        );

        if (hasEmbellishment) {
          return true;
        }
      }
    }
  }

  return false;
}

export function checkIfHasEmbellishmentTypeAndColourSelectionTechnique(
  configuration: any,
  embellishmentType: string,
  colorSelectionTechnique?: string
): boolean {
  const customizations = configuration.customizations;

  for (const side in customizations) {
    if (customizations.hasOwnProperty(side)) {
      const sideData = customizations[side];
      const artworks = sideData.artworks;

      if (Array.isArray(artworks)) {
        const hasEmbellishment = artworks.some((artwork: any) => {
          const hasCorrectEmbellishmentType = artwork.metadata?.embellishmentType === embellishmentType;
          const hasCorrectColorSelectionTechnique = colorSelectionTechnique
            ? artwork.metadata?.colorSelectionTechnique === colorSelectionTechnique
            : true;
          const hasImageUrl = artwork.metadata.imageUrl;
          return hasCorrectEmbellishmentType && hasCorrectColorSelectionTechnique && hasImageUrl;
        });
        if (hasEmbellishment) {
          return true;
        }
      }
    }
  }

  return false;
}

// Map the deciding property to the corresponding value in the configuration
export function mapDecidingProperty(
  decidingProperty: DecidingProperty,
  configuration: Tables<"configurations">,
  side: any
): any {
  switch (decidingProperty) {
    case DecidingProperty.color_type:
      return (configuration.color_metadata as unknown as ColorCustomization).category;
    case DecidingProperty.dtg_cumulative_height:
      return getDigitalPrintArtworksCumulativeHeight(configuration.customizations as unknown as CanvaOrientations);
    // return calculateCumulativeArtworkHeight(configuration);
    case DecidingProperty.label_size:
      const sizeArray = (configuration.label_metadata as unknown as LabelCustomization)?.size;
      if (Array.isArray(sizeArray) && sizeArray.length === 2) {
        const [width, height] = sizeArray;
        return `${width}x${height}`; // Return area as a string
      }
    case DecidingProperty.screen_print_artwork_size:
      // console.log("side***************", side);
      return calculateSmallestDimension(
        configuration,
        EmbellishmentType.ScreenPrint,
        ColorSelectionTechnique.Standard,
        side
      );
    case DecidingProperty.screen_print_puff_artwork_size:
      return calculateSmallestDimension(
        configuration,
        EmbellishmentType.ScreenPrint,
        ColorSelectionTechnique.Puff,
        side
      );
    case DecidingProperty.has_embroidery:
      return checkIfHasEmbellishmentType(configuration, EmbellishmentType.Embroidery);
    // return checkIfHasEmbroidery(configuration);
    case DecidingProperty.has_embroidery_with_puff:
      return checkIfHasEmbellishmentTypeAndColourSelectionTechnique(
        configuration,
        EmbellishmentType.Embroidery,
        ColorSelectionTechnique.Puff
      );
    case DecidingProperty.embroidery_surface:
      return calculateTotalArtworkSurfaceArea(
        configuration,
        EmbellishmentType.Embroidery,
        ColorSelectionTechnique.Standard,
        side
      );
    case DecidingProperty.dtg_surface:
      return calculateTotalArtworkSurfaceArea(
        configuration,
        EmbellishmentType.DigitalPrint,
        ColorSelectionTechnique.Standard,
        side
      );
    case DecidingProperty.dtf_surface:
      return calculateTotalArtworkSurfaceArea(
        configuration,
        EmbellishmentType.DTF,
        ColorSelectionTechnique.Standard,
        side
      );
    default:
      return undefined;
  }
}

// export function getRushedData(rushComponent: PriceModifierComponent | undefined, cart: JoinedCart) {
//   const leadTimeWithoutRush = calculateProductionTime(cart.line_items);
//   if (rushComponent) {
//     const { triggers, impact } = rushComponent;
//     const rushTrigger = (triggers as Trigger[])[0];

//     if (impact.price) {
//       if (rushTrigger.property == DecidingProperty.save_days) {
//         return {
//           leadTime: Number((leadTimeWithoutRush - rushTrigger.value) > 0 ? (leadTimeWithoutRush - rushTrigger.value) : 0),
//           rushOrderAddon: impact.price[0].price,
//           rushOption: rushComponent.id,
//         };
//       } else if (rushTrigger.property == DecidingProperty.deliver_in) {
//         return {
//           leadTime: rushTrigger.value as number,
//           rushOrderAddon: impact.price[0].price,
//           rushOption: rushComponent.id,
//         };
//       }
//       // else {
//       //   return {
//       //     leadTime: rushTrigger.value as number,
//       //      rushOrderAddon: impact.price[0].price,
//       //      rushOption: rushComponent.id,
//       //  }
//       // }
//     }
//   }
//   return { leadTime: leadTimeWithoutRush, rushOrderAddon: 0, rushOption: -1 };
// }
// export function getRushedData(
//   rushComponent: PriceModifierComponent | undefined,
//   cart: JoinedCart
// ) {
//   const leadTimeWithoutRush = calculateProductionTime(cart.line_items);

//   if (rushComponent) {
//     const { triggers, impact } = rushComponent;

//     // Find rush trigger (save_days or deliver_in)
//     const rushTrigger = (triggers as Trigger[]).find(
//       (trigger) =>
//         trigger.property === DecidingProperty.save_days ||
//         trigger.property === DecidingProperty.deliver_in
//     );

//     // Check if the price modifier should be a percentage of the invoice
//     const isPricePercentageTrigger = (triggers as Trigger[]).find(
//       (trigger) =>
//         trigger.property === DecidingProperty.is_price_percentage &&
//         trigger.operator === "eq" &&
//         trigger.value === "true"
//     );

//     if (impact.price && rushTrigger) {
//       const basePrice = cart.total_price || 0;

//       let rushOrderAddon = impact.price[0].price;

//       // If the trigger is enabled, calculate the percentage-based price
//       if (isPricePercentageTrigger) {
//         const percentage = rushTrigger.value; // The percentage value (e.g., 100 for 100%)
//         rushOrderAddon = (basePrice * percentage) / 100;
//       }

//       if (rushTrigger.property === DecidingProperty.save_days) {
//         return {
//           leadTime: Math.max(leadTimeWithoutRush - rushTrigger.value, 0),
//           rushOrderAddon,
//           rushOption: rushComponent.id,
//         };
//       } else if (rushTrigger.property === DecidingProperty.deliver_in) {
//         return {
//           leadTime: rushTrigger.value as number,
//           rushOrderAddon,
//           rushOption: rushComponent.id,
//         };
//       }
//     }
//   }

//   return { leadTime: leadTimeWithoutRush, rushOrderAddon: 0, rushOption: -1 };
// }

export function getRushedData(rushComponent: PriceModifierComponent | undefined, cart: JoinedCart) {
  const leadTimeWithoutRush = calculateProductionTime(cart.line_items);

  if (rushComponent) {
    const { triggers, impact } = rushComponent;

    // Find rush trigger (save_days or deliver_in)
    const rushTrigger = (triggers as Trigger[]).find(
      (trigger) => trigger.property === DecidingProperty.save_days || trigger.property === DecidingProperty.deliver_in
    );

    // Check if the price modifier should be a percentage of the invoice
    const isPricePercentageTrigger = (triggers as Trigger[]).find(
      (trigger) => trigger.property === "is_price_percentage" && trigger.operator === "eq" && trigger.value === "true"
    );

    if (impact.price && rushTrigger) {
      // Calculate the total price excluding any rush fees
      const baseTotalPrice = calculateCartTotalPrice({ ...cart });

      let rushOrderAddon = impact.price[0].price; // Default to the flat price

      // If the trigger is enabled, calculate the percentage-based price
      if (isPricePercentageTrigger) {
        const percentageImpact = impact.price[0].price; // Use the price value as the percentage
        rushOrderAddon = (baseTotalPrice * percentageImpact) / 100; // Apply percentage to the base price only
      }

      if (rushTrigger.property === DecidingProperty.save_days) {
        return {
          leadTime: Math.max(leadTimeWithoutRush - rushTrigger.value, 0),
          rushOrderAddon,
          rushOption: rushComponent.id,
        };
      } else if (rushTrigger.property === DecidingProperty.deliver_in) {
        return {
          leadTime: rushTrigger.value as number,
          rushOrderAddon,
          rushOption: rushComponent.id,
        };
      }
    }
  }

  return { leadTime: leadTimeWithoutRush, rushOrderAddon: 0, rushOption: -1 };
}

// Evaluate a trigger condition
function evaluateTrigger(value: any, operator: string, triggerValue: any): boolean {
  switch (operator) {
    case "eq":
      return value === triggerValue;
    case "neq":
      return value !== triggerValue;
    case "lt":
      return value < triggerValue;
    case "lte":
      return value <= triggerValue;
    case "gt":
      return value > triggerValue;
    case "gte":
      return value >= triggerValue;
    case "in":
      return Array.isArray(triggerValue) && triggerValue.includes(value);
    case "nin":
      return Array.isArray(triggerValue) && !triggerValue.includes(value);
    case "range":
      if (typeof triggerValue === "string" && triggerValue.includes("-")) {
        const [min, max] = triggerValue.split("-").map(Number);
        return value >= min && value <= max;
      }
      return false;
    default:
      return false;
  }
}

function mergePriceImpacts(existingImpacts: PriceImpact[], newImpacts: PriceImpact[]): PriceImpact[] {
  const allQuantities = new Set<number>();

  // Collect all unique quantities from both impacts
  existingImpacts.forEach((impact) => allQuantities.add(impact.qty));
  newImpacts.forEach((impact) => allQuantities.add(impact.qty));

  const sortedQuantities = Array.from(allQuantities).sort((a, b) => a - b);

  // Get the last known price from impacts for any quantity
  const getPriceAtQty = (impacts: PriceImpact[], qty: number): number => {
    const sortedImpacts = impacts.slice().sort((a, b) => a.qty - b.qty);
    let price = null;
    for (const impact of sortedImpacts) {
      if (impact.qty <= qty) {
        price = impact.price;
      } else {
        break;
      }
    }
    // Use the last known price or 0 if none
    return price !== null ? price : 0;
  };

  const getLeadTimeAtQty = (impacts: PriceImpact[], qty: number): number => {
    const sortedImpacts = impacts.slice().sort((a, b) => a.qty - b.qty);
    let leadTime = null;
    for (const impact of sortedImpacts) {
      if (impact.qty <= qty && impact.lead_time) {
        leadTime = impact.lead_time;
      } else {
        break;
      }
    }
    // Use the last known price or 0 if none
    return leadTime !== null ? leadTime : 0;
  };

  // Merge the impacts
  const mergedImpacts: PriceImpact[] = sortedQuantities.map((qty) => {
    const price1 = getPriceAtQty(existingImpacts, qty);
    const price2 = getPriceAtQty(newImpacts, qty);
    const leadTime1 = getLeadTimeAtQty(existingImpacts, qty);
    const leadTime2 = getLeadTimeAtQty(newImpacts, qty);

    return { qty, price: price1 + price2, lead_time: leadTime1 + leadTime2 };
  });

  return mergedImpacts;
}

export function getEmbellishmentDetails(productObject: any, targetEmbellishmentType: any) {
  const sides = Object.keys(productObject);
  const embellishmentInfo = {
    embellishmentType: targetEmbellishmentType,
    sides: [] as string[],
    artworkSizes: [] as number[],
  };

  sides.forEach((side) => {
    const artworks = productObject[side].artworks;
    if (artworks && artworks.length > 0) {
      artworks.forEach((artwork: any) => {
        if (
          artwork.metadata &&
          artwork.metadata.embellishmentType &&
          artwork.metadata.embellishmentType === targetEmbellishmentType &&
          artwork.name !== "placeholder"
        ) {
          if (!embellishmentInfo.sides.includes(side)) {
            embellishmentInfo.sides.push(side);
            embellishmentInfo.artworkSizes.push(artwork.dimensions.width * artwork.dimensions.height);
          }
        }
      });
    }
  });

  const sortedIndices = embellishmentInfo.artworkSizes
    .map((size, index) => ({ size, index }))
    .sort((a, b) => b.size - a.size)
    .map((item) => item.index);

  embellishmentInfo.sides = sortedIndices.map((index) => embellishmentInfo.sides[index]);
  embellishmentInfo.artworkSizes = sortedIndices.map((index) => embellishmentInfo.artworkSizes[index]);
  return embellishmentInfo;
}

export function getUniqueEmbellishmentTypes(customization: any) {
  const embellishmentTypes: string[] = [];
  Object.values(customization).forEach((customization: any) => {
    const artworks = customization.artworks;
    if (Array.isArray(artworks)) {
      artworks.forEach((artwork) => {
        if (artwork.metadata.embellishmentType && !embellishmentTypes.includes(artwork.metadata.embellishmentType)) {
          embellishmentTypes.push(artwork.metadata.embellishmentType);
        }
      });
    }
  });

  return embellishmentTypes;
}

export function calculateSingleItemImpact(
  product: JoinedProduct,
  configuration: Tables<"configurations">,
  priceModifiers: JoinedPriceModifier[]
): PriceChangeResult {
  const priceThresholds = product.thresholds as PriceThreshold[];

  const initialImpact =
    priceThresholds && priceThresholds.length > 0
      ? {
          moq: priceThresholds[0].threshold,
          lead_time: priceThresholds[0].leadTime,
          price: priceThresholds.map((priceThreshold) => ({
            qty: priceThreshold.threshold,
            price: priceThreshold.price,
            lead_time: priceThreshold.leadTime,
          })),
        }
      : {
          moq: 0,
          lead_time: 0,
          price: [],
        };

  let cumulativeImpact: Impact = cloneDeep(initialImpact);

  // Filter modifiers applicable to the product and of type 'line_item'
  const applicableModifiers = priceModifiers.filter((pm) => {
    const isLineItemType = pm.scope === PriceModifierScope.line_item;
    const isAssignedToProduct = pm.product_id === null || pm.product_id === undefined || pm.product_id === product.id;
    const isPricingPriceModifier = pm.modifier_type === PriceModifierType.price;
    return isLineItemType && isAssignedToProduct && !isPricingPriceModifier;
  });

  for (const side in configuration.customizations as any) {
    const sideData = (configuration.customizations as { [key: string]: any })[side];
    if (!sideData) continue;

    for (const pm of applicableModifiers) {
      let componentsRaw = pm.price_modifiers_components || [];
      const firstTimeComponentsRaw: PriceModifierComponent[] = [];
      const secondTimeComponentsRaw: PriceModifierComponent[] = [];
      const extraCostSides: string[] = [];
      if (pm.multi_side_extra_cost) {
        const embellishmentDetails = getEmbellishmentDetails(configuration.customizations, pm.embellishment_type);
        const appliedMoreThanOnce =
          embellishmentDetails && embellishmentDetails.sides && embellishmentDetails.sides.length > 1;

        embellishmentDetails.sides.forEach((side) => {
          if (!extraCostSides.includes(side)) {
            extraCostSides.push(side);
          }
        });

        if (appliedMoreThanOnce) {
          componentsRaw.forEach((item) => {
            if ((item.impact as Impact)?.extraCost === true) {
              secondTimeComponentsRaw.push(item as any as PriceModifierComponent);
            } else {
              firstTimeComponentsRaw.push(item as any as PriceModifierComponent);
            }
          });
        } else {
          componentsRaw
            .filter(function filterOutExtraCost(item: any) {
              return (item.impact as Impact)?.extraCost !== true;
            })
            .forEach((item) => {
              firstTimeComponentsRaw.push(item as any as PriceModifierComponent);
            });
        }
      }

      // Map over the raw components to parse triggers and impact
      const components = componentsRaw.map((comp) => {
        return {
          ...comp,
          triggers: comp.triggers ? (comp.triggers as Trigger[]) : null,
          impact: comp.impact ? (comp.impact as Impact) : {},
        };
      });

      for (const component of pm.multi_side_extra_cost && side == extraCostSides[0]
        ? firstTimeComponentsRaw
        : pm.multi_side_extra_cost && side !== extraCostSides[0]
        ? secondTimeComponentsRaw
        : components) {
        if (!component.triggers) {
          continue; // Skip this component if triggers are null
        }

        const triggersSatisfied = component.triggers.every((trigger) => {
          const configValue = mapDecidingProperty(trigger.property, configuration, sideData);
          return evaluateTrigger(configValue, trigger.operator, trigger.value);
        });

        if (triggersSatisfied) {
          const { impact } = component;
          if (impact.moq !== undefined) {
            cumulativeImpact.moq = Math.max(cumulativeImpact?.moq || 0, impact.moq);
          }

          if (impact.lead_time !== undefined) {
            cumulativeImpact.lead_time = (cumulativeImpact.lead_time || 0) + impact.lead_time;
          }

          if (impact.price !== undefined) {
            if (impact.impactPerCM2 && impact.embellishmentType) {
              // Calculate surface area for the specified embellishment type
              let totalSurfaceArea = calculateTotalArtworkSurfaceArea(
                configuration,
                impact.embellishmentType,
                ColorSelectionTechnique.Standard,
                sideData
              );
              const priceImpactPerCm2 = impact.price.map((priceImpact) => ({
                qty: priceImpact.qty,
                price: priceImpact.price * totalSurfaceArea,
                lead_time: priceImpact.lead_time,
              }));
              // Merge calculated price impacts based on surface area
              cumulativeImpact.price = mergePriceImpacts(cumulativeImpact.price || [], priceImpactPerCm2);
            } else {
              // Handle other price impacts
              cumulativeImpact.price = mergePriceImpacts(cumulativeImpact.price || [], impact.price);
            }
          }
        }
      }
    }
  }

  return {
    moqChange: cumulativeImpact.moq || 0,
    leadTimeChange: cumulativeImpact.lead_time || 0,
    priceChange: cumulativeImpact.price || [],
  };
}

// export function calculateSingleItemImpact(
//   product: JoinedProduct,
//   configuration: Tables<"configurations">,
//   priceModifiers: JoinedPriceModifier[]
// ): PriceChangeResult {
//   const priceThresholds = product.thresholds as PriceThreshold[];

//   const initialImpact =
//     priceThresholds && priceThresholds.length > 0
//       ? {
//           moq: priceThresholds[0].threshold,
//           lead_time: priceThresholds[0].leadTime,
//           price: priceThresholds.map((priceThreshold) => ({
//             qty: priceThreshold.threshold,
//             price: priceThreshold.price,
//           })),
//         }
//       : {
//           moq: 0,
//           lead_time: 0,
//           price: [],
//         };

//   let cumulativeImpact: Impact = initialImpact;

//   // Filter modifiers applicable to the product and of type 'line_item'
//   const applicableModifiers = priceModifiers.filter((pm) => {
//     const isLineItemType = pm.scope === PriceModifierScope.line_item;
//     const isAssignedToProduct = pm.product_id === null || pm.product_id === undefined || pm.product_id === product.id;
//     const isPricingPriceModifier = pm.modifier_type === PriceModifierType.price;
//     return isLineItemType && isAssignedToProduct && !isPricingPriceModifier;
//   });
//   for (const pm of applicableModifiers) {
//     const componentsRaw = pm.price_modifiers_components || [];

//     // Map over the raw components to parse triggers and impact
//     const components: PriceModifierComponent[] = componentsRaw.map((comp) => {
//       return {
//         ...comp,
//         triggers: comp.triggers ? (comp.triggers as Trigger[]) : null,
//         impact: comp.impact ? (comp.impact as Impact) : {},
//       };
//     });
//     for (const component of components) {

//       if (!component.triggers) {
//         continue; // Skip this component if triggers are null
//       }
//       const triggersSatisfied = component.triggers.every((trigger) => {
//         // const configValue = mapDecidingProperty(trigger.property, configuration);
//         // return evaluateTrigger(configValue, trigger.operator, trigger.value);
//         if (configuration.customizations && typeof configuration.customizations === 'object' && configuration.customizations !== null) {
//           return Object.keys(configuration.customizations).some((side) => {
//             // console.log('configurationszzzzzz',configuration )
//             const sideData = (configuration.customizations as { [key: string]: any })[side];
//             if (sideData) {
//               const configValue = mapDecidingProperty(trigger.property,configuration, sideData);
//               return evaluateTrigger(configValue, trigger.operator, trigger.value);
//             }
//             return false;
//           });
//         }
//         return false;
//       });
//       // console.log('triggersSatisfied', triggersSatisfied)
//       if (triggersSatisfied) {
//         const { impact } = component;

//         if (impact.moq !== undefined) {
//           cumulativeImpact.moq = Math.max(cumulativeImpact?.moq || 0, impact.moq);
//         }

//         if (impact.lead_time !== undefined) {
//           cumulativeImpact.lead_time = (cumulativeImpact.lead_time || 0) + impact.lead_time;
//         }

//         if (impact.price !== undefined) {
//           if (impact.impactPerCM2 && impact.embellishmentType && configuration.customizations) {
//             // Calculate surface area for the specified embellishment type
//             let totalSurfaceArea = 0;
//             for (const side in (configuration.customizations as any)) {
//               if (configuration.customizations.hasOwnProperty(side)) {
//                 totalSurfaceArea += calculateTotalArtworkSurfaceArea(configuration,impact.embellishmentType,  side);
//               }
//             }
//             const priceImpactPerCm2 = impact.price.map((priceImpact) => ({
//               qty: priceImpact.qty,
//               price: priceImpact.price * totalSurfaceArea,
//             }));
//             // Merge calculated price impacts based on surface area
//             cumulativeImpact.price = mergePriceImpacts(cumulativeImpact.price || [], priceImpactPerCm2);
//           } else {
//             // Handle other price impacts
//             cumulativeImpact.price = mergePriceImpacts(cumulativeImpact.price || [], impact.price);
//           }
//         }
//       }
//     }
//   }

//   return {
//     moqChange: cumulativeImpact.moq || 0,
//     leadTimeChange: cumulativeImpact.lead_time || 0,
//     priceChange: cumulativeImpact.price || [],
//   };
// }

// export function calculateCumulativeArtworkHeight(configuration: any): number {
//   let totalHeight = 0;

//   const customizations = configuration.customizations;

//   for (const side in customizations) {
//     if (customizations.hasOwnProperty(side)) {
//       const sideData = customizations[side];
//       const artworks = sideData.artworks;

//       if (Array.isArray(artworks)) {
//         artworks.forEach((artwork: any) => {
//           if (
//             artwork.dimensions &&
//             typeof artwork.dimensions.height === 'number' &&
//             artwork.metadata &&
//             artwork.metadata.embellishmentType === EmbellishmentType.DigitalPrint
//             // artwork.metadata.embellishmentType === 'digital-print'
//           ) {
//             totalHeight += artwork.dimensions.height;
//           }
//         });
//       }
//     }
//   }

//   return totalHeight;
// }

// export function calculateLeadTime(
//   product: JoinedProduct,
//   configuration: Tables<"configurations">,
//   priceModifiers: JoinedPriceModifier[]
// ): number {
//   const baseLeadTime = product.lead_time || 0;

//   // Get cumulative impacts using calculate Single Item Price
//   const impactChangeResult = calculateSingleItemImpact(product, configuration, priceModifiers);

//   const totalLeadTime = baseLeadTime + impactChangeResult.leadTimeChange;
//   return totalLeadTime;
// }

export function calculateProductionTime(lineItems: JoinedLineItem[]): number {
  const productionTime = Math.max(
    ...lineItems.map((item) => {
      if (
        item.price_details
        // &&
        // typeof item.price_details === 'object' &&
        // 'leadTimeChange' in item.price_details &&
        // typeof item.price_details.leadTimeChange === 'number'
      ) {
        const itemTotalQuantity = sumBy(item.size_allocations, "quantity");
        const leadTime = (item.price_details as unknown as PriceChangeResult).priceChange.find(
          (price) => itemTotalQuantity >= price.qty
        )?.lead_time;
        return leadTime || 0;
      }
      return 1; // Default lead time if not specified
    })
  );

  console.log("🥰", { productionTime });

  return productionTime;
}

export function calculateMOQ(
  product: JoinedProduct,
  configuration: Tables<"configurations">,
  priceModifiers: JoinedPriceModifier[]
): any {
  // if (priceModifiers.length === 0) return 0;
  // Get cumulative impacts using calculate Single Item Price
  const impactChangeResult = calculateSingleItemImpact(product, configuration, priceModifiers);

  return impactChangeResult.moqChange;
}

export function calculateLineItemPrice(
  product: JoinedProduct,
  configuration: Tables<"configurations">,
  priceModifiers: JoinedPriceModifier[],
  quantity?: number
): number {
  // Get cumulative impacts using calculateSingleItemImpact
  const impactChangeResult = calculateSingleItemImpact(product, configuration, priceModifiers);

  if (impactChangeResult.priceChange.length === 0) {
    return 0;
  }

  let applicablePriceImpact;

  if (quantity !== undefined) {
    // Find the highest price impact with qty less than or equal to the given quantity
    applicablePriceImpact = impactChangeResult.priceChange
      .filter((priceImpact) => priceImpact.qty <= quantity)
      .reduce((maxPriceImpact, currentPriceImpact) => {
        return currentPriceImpact.qty > maxPriceImpact.qty ? currentPriceImpact : maxPriceImpact;
      });
  } else {
    // Get the price with the least qty
    applicablePriceImpact =
      impactChangeResult.priceChange.find((priceImpact) => priceImpact.qty === impactChangeResult.moqChange) ||
      impactChangeResult.priceChange[0];
    // applicablePriceImpact = impactChangeResult.priceChange.reduce((minPriceImpact, currentPriceImpact) => {
    //   return currentPriceImpact.qty < minPriceImpact.qty ? currentPriceImpact : minPriceImpact;
    // });
  }

  return Math.round(Number(applicablePriceImpact.price) * 100) / 100;
}

// export function getItemPriceFromImpact(
//   // product: JoinedProduct,
//   // configuration: Tables<'configurations'>,
//   Impact: PriceChangeResult,
//   quantity?: number
// ): number {
//   // Get cumulative impacts using calculateSingleItemImpact
//   // const impactChangeResult = calculateSingleItemImpact(
//   //   product,
//   //   configuration,
//   //   priceModifiers
//   // );

//   // if (impactChangeResult.priceChange.length === 0) {
//   //   throw new Error('No price impacts available for the given product and configuration');
//   // }

//   let applicablePriceImpact;

//   if (quantity !== undefined) {
//     if (quantity === 0) return 0;
//     // Find the highest price impact with qty less than or equal to the given quantity
//     applicablePriceImpact = Impact.priceChange
//       .filter(priceImpact => priceImpact.qty <= quantity)
//       .reduce((maxPriceImpact, currentPriceImpact) => {
//         return currentPriceImpact.qty > maxPriceImpact.qty ? currentPriceImpact : maxPriceImpact;
//       });
//   } else {
//     // Get the price with the least qty
//     applicablePriceImpact = Impact.priceChange.reduce((minPriceImpact, currentPriceImpact) => {
//       return currentPriceImpact.qty < minPriceImpact.qty ? currentPriceImpact : minPriceImpact;
//     });
//   }

//   return applicablePriceImpact.price;
// }

export function getItemPriceFromImpact(Impact: PriceChangeResult, quantity?: number) {
  if (!Impact.priceChange || Impact.priceChange.length === 0) {
    return 0;
  }

  let applicablePriceImpact;

  if (quantity !== undefined) {
    // if (quantity === 0) return 0;
    // Find the highest price impact with qty less than or equal to the given quantity
    const filteredPriceImpacts = Impact.priceChange.filter((priceImpact) => priceImpact.qty <= quantity);

    if (filteredPriceImpacts.length === 0) {
      return 0;
    }

    applicablePriceImpact = filteredPriceImpacts.reduce((maxPriceImpact, currentPriceImpact) => {
      return currentPriceImpact.qty > maxPriceImpact.qty ? currentPriceImpact : maxPriceImpact;
    });
  } else {
    // Get the price with the least qty
    applicablePriceImpact = Impact.priceChange.reduce((minPriceImpact, currentPriceImpact) => {
      return currentPriceImpact.qty < minPriceImpact.qty ? currentPriceImpact : minPriceImpact;
    }, Impact.priceChange[0]);
  }

  return Math.round(Number(applicablePriceImpact.price) * 100) / 100;
}

export function getOperatorsFromTriggerProperty(property: string): {
  label: string;
  value: string;
}[] {
  switch (property) {
    case DecidingProperty.color_type:
      return booleanTriggerOperators;
    case DecidingProperty.label_size:
      return booleanTriggerOperators;
    case DecidingProperty.has_embroidery:
      return booleanTriggerOperators;
    case DecidingProperty.has_embroidery_with_puff:
      return booleanTriggerOperators;
    case DecidingProperty.deliver_in:
      return rushOrdersOperators;
    case DecidingProperty.save_days:
      return rushOrdersOperators;
    default:
      return numberTriggerOperators;
  }
}

export function getPricingPriceModifier(modifiers: JoinedPriceModifier[]) {
  return find(modifiers, matchesProperty("modifier_type", PriceModifierType.price));
}

export function getLowestQuantityPrice(assignments: JoinedPriceModifierAssignment[]) {
  for (const assignment of assignments) {
    const { price_modifiers } = assignment;

    if (price_modifiers.modifier_type === PriceModifierType.price && price_modifiers.price_modifiers_components) {
      const allPrices = price_modifiers.price_modifiers_components.flatMap(
        (component) => (component.impact as Impact).price
      );

      if (allPrices.length > 0) {
        const lowestQuantityPrice = allPrices.reduce(
          (lowest, current) => ((current?.qty as number) < (lowest?.qty as number) ? current : lowest),
          allPrices[0]
        );

        return lowestQuantityPrice ? lowestQuantityPrice.price : null;
      }
    }
  }
  return null;
}

export function getLineItemTriggeredComponents(
  product: JoinedProduct,
  configuration: Tables<"configurations">,
  priceModifiers: JoinedPriceModifier[]
): PriceModifierComponent[] {
  const individualImpacts: PriceModifierComponent[] = [];

  const applicableModifiers = priceModifiers.filter((pm) => {
    const isLineItemType = pm.scope === PriceModifierScope.line_item;
    const isAssignedToProduct = pm.product_id === null || pm.product_id === undefined || pm.product_id === product.id;
    const isApplicable = pm.status === PriceModifierStatus.enabled && !pm.deleted_at;
    return isLineItemType && isAssignedToProduct && isApplicable;
  });

  for (const side in configuration.customizations as any) {
    const sideData = (configuration.customizations as { [key: string]: any })[side];
    if (!sideData) continue;

    for (const pm of applicableModifiers) {
      let componentsRaw = pm.price_modifiers_components || [];
      const firstTimeComponentsRaw: PriceModifierComponent[] = [];
      const secondTimeComponentsRaw: PriceModifierComponent[] = [];
      const appliedMoreThanOnceSides: string[] = [];

      if (pm.multi_side_extra_cost) {
        const embellishmentDetails = getEmbellishmentDetails(configuration.customizations, pm.embellishment_type);
        const appliedMoreThanOnce =
          embellishmentDetails && embellishmentDetails.sides && embellishmentDetails.sides.length > 1;
        if (appliedMoreThanOnce) {
          embellishmentDetails.sides.forEach((side) => {
            if (!appliedMoreThanOnceSides.includes(side)) {
              appliedMoreThanOnceSides.push(side);
            }
          });
          componentsRaw.forEach((item) => {
            if ((item.impact as Impact)?.extraCost === true) {
              secondTimeComponentsRaw.push(item as PriceModifierComponent);
            } else {
              firstTimeComponentsRaw.push(item as PriceModifierComponent);
            }
          });
        } else {
          componentsRaw
            .filter((item) => (item.impact as Impact)?.extraCost !== true)
            .forEach((item) => {
              firstTimeComponentsRaw.push(item as PriceModifierComponent);
            });
        }
      }

      const componentsToProcess =
        pm.multi_side_extra_cost && side === appliedMoreThanOnceSides[0]
          ? firstTimeComponentsRaw
          : pm.multi_side_extra_cost && side !== appliedMoreThanOnceSides[0]
          ? secondTimeComponentsRaw
          : componentsRaw.map((comp) => ({
              ...comp,
              triggers: comp.triggers ? (comp.triggers as Trigger[]) : null,
              impact: comp.impact ? (comp.impact as Impact) : {},
            }));

      for (const component of componentsToProcess) {
        if (!component.triggers) {
          continue;
        }

        const triggersSatisfied = component.triggers.every((trigger) => {
          const configValue = mapDecidingProperty(trigger.property, configuration, sideData);
          return evaluateTrigger(configValue, trigger.operator, trigger.value);
        });

        if (triggersSatisfied) {
          individualImpacts.push(component);
        }
      }
    }
  }

  return individualImpacts;
}

// export function getLineItemTriggeredComponents(
//   product: JoinedProduct,
//   configuration: Tables<"configurations">,
//   priceModifiers: JoinedPriceModifier[]
// ): PriceModifierComponent[] {
//   const individualImpacts: PriceModifierComponent[] = [];

//   const applicableModifiers = priceModifiers.filter((pm) => {
//     const isLineItemType = pm.scope === PriceModifierScope.line_item;
//     const isAssignedToProduct = pm.product_id === null || pm.product_id === undefined || pm.product_id === product.id;
//     const isApplicable = pm.status == PriceModifierStatus.enabled && !pm.deleted_at;
//     return isLineItemType && isAssignedToProduct && isApplicable;
//   });

//   for (const side in configuration.customizations as any) {
//     const sideData = (configuration.customizations as { [key: string]: any })[side];
//     if (!sideData) continue;

//     for (const pm of applicableModifiers) {
//       const componentsRaw = pm.price_modifiers_components || [];

//       const components: PriceModifierComponent[] = componentsRaw.map((comp) => {
//         return {
//           ...comp,
//           triggers: comp.triggers ? (comp.triggers as Trigger[]) : null,
//           impact: comp.impact ? (comp.impact as Impact) : {},
//         };
//       });

//       for (const component of components) {
//         if (!component.triggers) {
//           continue;
//         }

//         const triggersSatisfied = component.triggers.every((trigger) => {
//           const configValue = mapDecidingProperty(trigger.property, configuration, sideData);
//           return evaluateTrigger(configValue, trigger.operator, trigger.value);
//         });

//         if (triggersSatisfied) {
//           const { impact } = component;
//           let priceImpact: PriceImpact[];

//           if (impact.price !== undefined) {
//             if (impact.impactPerCM2 && impact.embellishmentType) {
//               let totalSurfaceArea = calculateTotalArtworkSurfaceArea(
//                 configuration,
//                 impact.embellishmentType,
//                 ColorSelectionTechnique.Standard,
//                 sideData
//               );
//               priceImpact = impact.price.map((priceImpact) => ({
//                 qty: priceImpact.qty,
//                 price: priceImpact.price * totalSurfaceArea,
//               }));
//             } else {
//               priceImpact = impact.price;
//             }

//             individualImpacts.push(component);
//           }
//         }
//       }
//     }
//   }

//   return individualImpacts;
// }

// export function getLineItemTriggeredComponents(
//   product: JoinedProduct,
//   configuration: Tables<"configurations">,
//   priceModifiers: JoinedPriceModifier[]
// ): PriceModifierComponent[] {
//   const individualImpacts: PriceModifierComponent[] = [];

//   const applicableModifiers = priceModifiers.filter((pm) => {
//     const isLineItemType = pm.scope === PriceModifierScope.line_item;
//     const isAssignedToProduct = pm.product_id === null || pm.product_id === undefined || pm.product_id === product.id;
//     const isApplicable = pm.status == PriceModifierStatus.enabled && !pm.deleted_at;
//     return isLineItemType && isAssignedToProduct && isApplicable;
//   });

//   for (const pm of applicableModifiers) {
//     const componentsRaw = pm.price_modifiers_components || [];

//     const components: PriceModifierComponent[] = componentsRaw.map((comp) => {
//       return {
//         ...comp,
//         triggers: comp.triggers ? (comp.triggers as Trigger[]) : null,
//         impact: comp.impact ? (comp.impact as Impact) : {},
//       };
//     });

//     for (const component of components) {
//       if (!component.triggers) {
//         continue;
//       }

//       const triggersSatisfied = component.triggers.every((trigger) => {
//         if (configuration.customizations !== null && configuration.customizations !== undefined) {
//           return Object.keys(configuration.customizations).some((side) => {
//             const sideData = (configuration.customizations as { [key: string]: any })[side];
//             if (sideData) {
//               const configValue = mapDecidingProperty(trigger.property,configuration, sideData);
//               return evaluateTrigger(configValue, trigger.operator, trigger.value);
//             }
//             return false;
//           });

//         }
//         return false;
//       });

//       if (triggersSatisfied) {
//         const { impact } = component;
//         let priceImpact: PriceImpact[];

//         if (impact.price !== undefined) {
//           if (impact.impactPerCM2 && impact.embellishmentType) {
//             let totalSurfaceArea = 0;
//             if (configuration.customizations && typeof configuration.customizations === 'object' && !Array.isArray(configuration.customizations)) {
//               for (const side in configuration.customizations) {
//                 if (configuration.customizations.hasOwnProperty(side)) {
//                   totalSurfaceArea += calculateTotalArtworkSurfaceArea(configuration, side, impact.embellishmentType);
//                 }
//               }
//             }
//             priceImpact = impact.price.map((priceImpact) => ({
//               qty: priceImpact.qty,
//               price: priceImpact.price * totalSurfaceArea,
//             }));
//           } else {
//             priceImpact = impact.price;
//           }

//           individualImpacts.push(component);
//         }

//       }
//     }
//   }

//   return individualImpacts;
// }

// export function getLineItemTriggeredComponents(
//   product: JoinedProduct,
//   configuration: Tables<"configurations">,
//   priceModifiers: JoinedPriceModifier[]
// ): PriceModifierComponent[] {
//   const individualImpacts = [];

//   const applicableModifiers = priceModifiers.filter((pm) => {
//     const isLineItemType = pm.scope === PriceModifierScope.line_item;
//     const isAssignedToProduct = pm.product_id === null || pm.product_id === undefined || pm.product_id === product.id;
//     const isApplicable = pm.status == PriceModifierStatus.enabled && !pm.deleted_at;
//     return isLineItemType && isAssignedToProduct && isApplicable;
//   });
//   for (const pm of applicableModifiers) {
//     const componentsRaw = pm.price_modifiers_components || [];

//     const components: PriceModifierComponent[] = componentsRaw.map((comp) => {
//       return {
//         ...comp,
//         triggers: comp.triggers ? (comp.triggers as Trigger[]) : null,
//         impact: comp.impact ? (comp.impact as Impact) : {},
//       };
//     });

//     for (const component of components) {
//       if (!component.triggers) {
//         continue;
//       }

//       const triggersSatisfied = component.triggers.every((trigger) => {
//         // const configValue = mapDecidingProperty(trigger.property, configuration);
//         // return evaluateTrigger(configValue, trigger.operator, trigger.value);
//         if (configuration.customizations !== null && configuration.customizations !== undefined) {
//           return Object.keys(configuration.customizations).some((side) => {
//             const sideData = (configuration.customizations as { [key: string]: any })[side];
//             if (sideData) {
//               const configValue = mapDecidingProperty(trigger.property,configuration, sideData);
//               return evaluateTrigger(configValue, trigger.operator, trigger.value);
//             }
//             return false;
//           });

//         }
//         return false;
//       });

//       if (triggersSatisfied) {
//         const { impact } = component;
//         let priceImpact: PriceImpact[];

//         if (impact.price !== undefined) {
//           if (impact.impactPerCM2 && impact.embellishmentType) {
//             const surfaceArea = calculateTotalArtworkSurfaceArea(configuration, impact.embellishmentType);
//             priceImpact = impact.price.map((priceImpact) => ({
//               qty: priceImpact.qty,
//               price: priceImpact.price * surfaceArea,
//             }));
//           } else {
//             priceImpact = impact.price;
//           }

//           individualImpacts.push(component);
//         }
//       }
//     }
//   }

//   return individualImpacts;
// }
