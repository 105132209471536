"use client";
import { createClient } from "src/lib/supabase/client";
import store from "src/app/store";
import { Tables } from "src/types";
import useSWRImmutable from "swr/immutable";
import { User } from "@supabase/supabase-js";

const supabase = createClient();
// export type ProfileWithTenants = Tables<"profiles"> & { tenants: Tables<"tenants">[] };

// export async function handleNewSession(user: User): Promise<Tables<"tenants">[] | null> {
//   const { data: profileData, error: profileError } = await supabase
//     .from("profiles")
//     .select(`*, tenants:profiles_tenants(tenant_id(*))`)
//     //.eq("profiles.profile_id", "profiles.id")
//     .eq("uuid", user.id)
//     .single();
//   if (profileData && profileData.tenants) profileData.tenants = profileData.tenants.map((t: any) => t.tenant_id);

//   store.getActions().setProfile(profileData as any as ProfileWithTenants);
//   console.log("adminTenant", profileData);
//   // Get stored tenant ID from localStorage
//   const storedTenantId = localStorage.getItem("lastAdminTenant");
//   const tenants = (profileData as any).tenants;

//   if (tenants.length > 0) {
//     // If we have a stored tenant ID, try to find it in the user's tenants
//     if (storedTenantId) {
//       const savedTenant = tenants.find((t: any) => t.id === storedTenantId);
//       // Use the saved tenant if found, otherwise fallback to first tenant
//       store.getActions().setAdminTenant(savedTenant || tenants[0]);
//     } else {
//       // If no stored tenant, fallback to first tenant
//       store.getActions().setAdminTenant(tenants[0]);
//     }
//     return tenants;
//   } else {
//     store.getActions().setAdminTenant(null);
//     return null;
//   }
// }
export type AdminTenantWithRoles = {
  roles: string[] | null;
  tenants: Tables<"tenants"> | null;
};

export type ProfileWithTenants = Tables<"profiles"> & {
  profiles_tenants: AdminTenantWithRoles[];
};

export async function handleNewSession(user: User): Promise<ProfileWithTenants | null> {
  const { data: profileData, error: profileError } = await supabase
    .from("profiles")
    .select(` *, profiles_tenants ( roles, tenants ( *, tenants ( * ) ) ) `)
    //.eq("profiles.profile_id", "profiles.id")
    .eq("uuid", user.id)
    .single();

  console.log("adminTenant", profileData);
  // if (profileData && profileData.profiles_tenants)
  //   profileData.tenants = profileData.tenants.map((t: any) => t.tenant_id);

  store.getActions().setProfile(profileData as any as ProfileWithTenants);
  // Get stored tenant ID from localStorage
  const storedTenantId = localStorage.getItem("lastAdminTenant");
  const profileTenants = profileData?.profiles_tenants;

  if (profileTenants && profileTenants.length > 0) {
    // If we have a stored tenant ID, try to find it in the user's tenants
    if (storedTenantId) {
      // const savedTenant = profileTenants.find((t) => t.tenants?.id === storedTenantId)?.tenants;
      const savedTenant = profileTenants.find((t) => t.tenants?.id === storedTenantId);
      // Use the saved tenant if found, otherwise fallback to first tenant
      store.getActions().setAdminTenant(savedTenant || profileTenants[0]);
    } else {
      // If no stored tenant, fallback to first tenant
      store.getActions().setAdminTenant(profileTenants[0]);
    }
    return profileData as ProfileWithTenants;
  } else {
    store.getActions().setAdminTenant(null);
    return null;
  }
}

/**
 * Need to implement this: https://supabase.com/docs/guides/database/postgres/custom-claims-and-role-based-access-control-rbac
 * @returns
 */
export function useUser() {
  const { data, error, isLoading, mutate } = useSWRImmutable("user", async () => {
    const { data, error } = await supabase.auth.getUser();
    if (data.user != null /*&& (!profile || !profile.id)*/) {
      await handleNewSession(data.user);
    }
    return data.user;
  });

  return { data, error, isLoading, mutate };
}

// /**
//  * Need to implement this: https://supabase.com/docs/guides/database/postgres/custom-claims-and-role-based-access-control-rbac
//  * @returns
//  */

//  export function useUser() {
//   const { data, error, isLoading, mutate } = useSWRImmutable("user", async () => {
//     // Fetch user information
//     const { data, error } = await supabase.auth.getUser();

//     if (!error && data.user) {
//       const user = data.user;

//       // Fetch profile and tenants information
//       const { data: profileData, error: profileError } = await supabase
//         .from("profiles")
//         .select(`*, tenants:profiles_tenants(tenant_id(*), roles)`)
//         .eq("uuid", user.id)
//         .single();

//       if (!profileError && profileData && profileData.tenants) {
//         // Filter tenants to only include those where roles include "dashboard.view"
//         const filteredTenants = profileData.tenants.filter(
//           (tenant: any) => tenant.roles && tenant.roles.includes("dashboard.view")
//         );

//         if (filteredTenants.length > 0) {
//           // Extract tenant IDs for the filtered tenants
//           const tenantIds = filteredTenants.map((tenant: any) => tenant.tenant_id.id);

//           // Fetch full tenant details for filtered tenants
//           const { data: tenantDetails, error: tenantDetailsError } = await supabase
//             .from("tenants")
//             .select("*")
//             .in("id", tenantIds);

//           if (!tenantDetailsError && tenantDetails && tenantDetails.length > 0) {
//             // Update tenants in profileData with the full tenant details
//             profileData.tenants = tenantDetails.map((tenant: any) => {
//               const matchedTenant = filteredTenants.find((t: any) => t.tenant_id.id === tenant.id);
//               return {
//                 ...tenant,
//                 roles: matchedTenant?.roles,
//               };
//             });

//             // Set profile in store
//             store.getActions().setProfile(profileData as any as ProfileWithTenants);

//             // Get stored tenant ID from localStorage
//             const storedTenantId = localStorage.getItem("lastAdminTenant");
//             const tenants = profileData.tenants;

//             if (tenants.length > 0) {
//               // If we have a stored tenant ID, try to find it in the user's tenants
//               if (storedTenantId) {
//                 const savedTenant = tenants.find((t: any) => t.id === storedTenantId);
//                 // Use the saved tenant if found, otherwise fallback to first tenant
//                 store.getActions().setAdminTenant(savedTenant || tenants[0]);
//               } else {
//                 // If no stored tenant, fallback to first tenant
//                 store.getActions().setAdminTenant(tenants[0]);
//               }
//             } else {
//               store.getActions().setAdminTenant(null);
//             }
//           } else {
//             console.log("No valid tenants found or error fetching tenant details", tenantDetailsError);
//             store.getActions().setAdminTenant(null);
//           }
//         } else {
//           console.log("No tenants with 'dashboard.view' access found");
//           store.getActions().setAdminTenant(null);
//         }
//       } else {
//         console.log("Profile error", profileError);
//         store.getActions().setAdminTenant(null);
//       }
//     }

//     return data?.user || null;
//   });

//   return { data, error, isLoading, mutate };
// }
