import { ColorCustomization, Size } from "@/types/supabase-custom/Customization";
import { SupportedCurrency } from "src/types/enums/supportedCurrency.enum";

export default {
  STRIPE_CHECK_ORDER_STATUS_INTERVAL_IN_MS: 5000,
  STRIPE_CC_MAX_ATTEMPTS: 7,
  VARIANT_OPTIONS: ["Size", "Color", "Amount", "Title"],
  BASE_LEAD_TIME: 6,
  SUPPORTED_CURRENCIES: [
    SupportedCurrency.USD,
    SupportedCurrency.EUR,
    // SupportedCurrency.GBP,
    // SupportedCurrency.CHF,
    // SupportedCurrency.CAD,
  ],
  // DEFAULT_COLOR: {
  //   name: "Bright White",
  //   hex: "#FFFFFF",
  //   pantone: null,
  //   category: "pre-developed",
  // } as ColorCustomization,
  CANVA_BG_CONTRAST: 0.5,
  CANVA_BG_ENHANCE: 0.1,
  MIN_PURCHASE_QUANTITY: 50,
  STORAGE_BASE_URL: `${process.env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public`,
  INITIAL_ARTWORK_WIDTH_PERCENTAGE: 0.75,
  MAX_WIDTH_PRODUCT_IMAGE: 900,
  MAX_HEIGHT_PRODUCT_IMAGE: 1100,
  PIXELS_PER_CM: 9,
  OFFSET_BELOW_NECK_TAPE: 0.5,
  ARTWORK_MINIMUM_SIZES_OPTIONS: [
    Size.XS,
    Size.S,
    Size.M,
    Size.L,
    Size.XL,
    Size["2XL"],
    Size["3XL"],
    Size["4XL"],
    Size["5XL"],
  ],
  TOP_PADDING: 150,
  STRIPE_TAX_CODE: "txcd_10000000",
  CUSTOM_IMAGE_URL_SUFFIX: "_custom_image_url",
  ICON_SIZE_PX: 40,
  INITIAL_ARTWORK_PERCENTAGE: 0.75,
  NECK_SEAM_Y_CM: 5,
  stickyBarHeightInRems: 3.5,
  PAGE_SIZE: 20,
  ALPHABET: "abcdefghijklmnopqrstuvwxyz".split(""),
  PRICE_MODIFIERS_PRICE_THRESHOLDS: ["50", "100", "150", "300"],
  MINIMUM_PRICE_LIST_THRESHOLD: 50,
  CUSTOMERS_PER_PAGE: 10,
  LOCATIONS_PER_PAGE: 10,
  PRODUCTS_PER_PAGE: 12,
  ORDERS_PER_PAGE: 10,
  ABANDONED_CHECKOUTS_THRESHOLDS: [
    { label: "1 hour", value: 1 },
    { label: "6 hours", value: 6 },
    { label: "1 day", value: 24 },
    { label: "1 week", value: 168 },
    { label: "2 weeks", value: 336 },
    { label: "1 month", value: 720 },
  ],
  ABANDONED_CHECKOUTS_INITIAL_THRESHOLD: 24,
  CUTOFF_TIME_FORMAT: "h:mma, z",
  CUTOFF_TIMEZONE_REGEX: /, (\w+\/\w+|\w+)/,
  DEFAULT_ALLOWED_FILE_TYPES: ["jpg", "jpeg", "png", "ai", "svg", "pdf", "webp"],
};
