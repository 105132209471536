import { action, thunk, computed } from "easy-peasy";
import { parse } from "path";
import koalaApiClient from "src/apis/koala-api-client";
import koalaUtils from "src/helpers/koala/koalaUtils";
import removeFromLocalStorage from "src/helpers/local-storage/removeFromLocalStorage";
import setLocalStorage from "src/helpers/local-storage/setLocalStorage";
import { KoalaInsertCart } from "src/types/koala/KoalaCart";
import { StoreModel } from "src/types/store/StoreModel";
import { EmbellishmentType } from "src/types/enums/embellishmentType.enum";
import { ArtworkSelectionStep } from "src/types/enums/artworkSelectionStep.enum";
import { productStyleType } from "src/types/enums/productStyleType.enum";
import { OrientationType } from "src/types/enums/orientationType.enum";
import { Tables } from "src/types";
import { PostgrestError } from "@supabase/supabase-js";
import moment from "moment";
import { WovenLabelPositionType } from "src/types/enums/wovenLabelPositionType.enum";
import { WovenLabelStitchType } from "src/types/enums/wovenLabelStitchType.enum";
import { SupabaseUtils } from "@/lib/supabase/supabaseUtils";
import { createClient } from "@/lib/supabase/client";
import { Attribute, JoinedCart } from "@/types/supabase-custom/JoinedCart";
import { ok, err } from "neverthrow";
import {
  calculateCartSubtotal,
  calculateCartTotalPrice,
  calculateDiscount,
  // calculateProductionTime,
  calculateShippingFees,
  calculateTotalLineItemQuantity,
  updateAttributes,
} from "@/lib/utils/cartUtils";
import { find, matchesProperty, omit, sumBy } from "lodash";
import { JoinedCheckout, JoinedOrder } from "@/types/supabase-custom/JoinedCheckout";
import { JoinedConfiguration } from "@/types/supabase-custom/JoinedConfiguration";
import axios from "axios";
import getLocalStorage from "@/helpers/local-storage/getLocalStorage";
import { InvoicingDetails } from "@/types/user-details/InvoicingDetails";
import { ShippingDetails } from "@/types/user-details/ShippingDetails";
import { AdminTenantWithRoles, ProfileWithTenants } from "@/hooks/useUser";
import {
  calculateLineItemPrice,
  calculateProductionTime,
  calculateSingleItemImpact,
  getPriceModifiersFromIds,
  getRushedData,
} from "@/lib/utils/priceModifierUtils";
import { JoinedPriceModifier, PriceModifierComponent } from "@/types/price-modifiers/priceModifiers";
import { NewJoinedOrder } from "@/types/supabase-custom/JoinedOrder";

const supabaseUtils = new SupabaseUtils(createClient());

export const cartQuery = `
*, 
line_items (
  *,
  configurations (
    *,
    products (
* ,
          price_modifiers_assignments ( *, price_modifiers ( *, price_modifiers_components ( * ) ) ),
          price_lists (
            *,
            price_lists_components (
              *
            )
            )
    )
  )
),
discounts (
  *
)
`;

export const checkoutQuery = `
*, 
      orders!orders_checkout_id_fkey ( * ),
carts!checkouts_cart_id_fkey ( *, 
      line_items (
        *,
        configurations (
          *,
          products (
            *,
            price_lists (
              *,
              price_lists_components (
                *
              )
              )
          )
          )
      ),
      discounts (
        *
        ) ), profiles ( * ),
          invoicing_address:addresses!checkouts_invoicing_address_id_fkey ( * ),
           shipping_address:addresses!checkouts_shipping_address_id_fkey ( * )`;

export const orderQuery = `
*,
tenant:tenants ( * ),
checkout:checkouts!orders_checkout_id_fkey ( *,
  cart:carts!checkouts_cart_id_fkey ( *, 
    line_items (
      *,
      configurations (
        *,
        products (
          *,
          price_lists (
            *,
            price_lists_components (
              *
            )
          )
        )
      )
    ),
    discounts (
      *
    )
  ),
  profiles ( * ),
  invoicing_address:addresses!checkouts_invoicing_address_id_fkey ( * ),
  shipping_address:addresses!checkouts_shipping_address_id_fkey ( * )
)
`;

const cachedInvoicingDetails: InvoicingDetails = getLocalStorage("user_invoicing_information");
const cachedShippingDetails: ShippingDetails = getLocalStorage("user_shipping_information");
const cachedShippingSameAsInvoicingFlag = getLocalStorage("shipping_same_as_invoicing");

const storeModel: StoreModel = {
  /**
   * This is used in Studio
   */
  tenant: null,
  setTenant: action((state, payload: Tables<"tenants"> | null) => {
    state.tenant = payload;
  }),

  selectedShippingRateOptionId: 0,
  setSelectedShippingRateOptionId: action((state, payload: number) => {
    state.selectedShippingRateOptionId = payload;
  }),

  tenantModifiers: [],
  setTenantModifiers: action((state, payload: JoinedPriceModifier[]) => {
    state.tenantModifiers = payload;
  }),
  tenantOrders: [],
  setTenantOrders: action((state, payload: NewJoinedOrder[]) => {
    state.tenantOrders = payload;
  }),
  tenantOrdersCount: 0,
  setTenantOrdersCount: action((state, payload: number) => {
    state.tenantOrdersCount = payload;
  }),

  /**
   * This is used in admin-store (we don't use the same as Studio as this is stored in localStorage)
   */
  adminTenant: null,
  setAdminTenant: action((state, payload: AdminTenantWithRoles | null) => {
    state.adminTenant = payload;
  }),
  // adminTenant: null,
  // setAdminTenant: action((state, payload: Tables<"tenants"> | null) => {
  //   state.adminTenant = payload;
  // }),

  error: null,
  setError: action((state, payload: PostgrestError | { code: string; message: string } | null) => {
    if (payload == null) state.error = null;
    else {
      state.error = { ...payload, timestamp: moment() };
    }
  }),

  profile: null,
  setProfile: action((state, payload: ProfileWithTenants | null) => {
    state.profile = payload;
  }),

  // states
  configuration: null,
  cart: null,
  checkout: null,
  order: null,
  isCartSidebarExpanded: false,

  // states from Product.tsx
  // selectedColor: null,
  // selectedColorName: "",
  // selectedColorCss: "",
  colorChanged: false,
  isColorMenuOpen: false,
  productImg: null,
  imageDimensions: { width: 0, height: 0 },
  rgbValues: {
    red: 0,
    green: 0,
    blue: 0,
  },
  // currentOrientation: "Front",
  // currentOrientation: OrientationType.Front,
  currentLeadTime: 0,
  showLeadTimeInfo: false,
  // artworkWidth: 1,
  // fromNeckSeam: 5,
  // fromCenter: 0,
  artworkColor: null,
  showGrid: true,
  showLeftChest: false,
  isProductLoading: true,
  artworkSummary: { technique: "" },
  // artworkImageUrl: null,
  // artworkImage: null,
  selectedSizeDimensions: null,
  selectedSizeDimensionsOriented: null,
  isArtworkMenuOpen: false,
  artworkDone: false,
  artworkDoneOriented: {
    [OrientationType.Front]: false,
    [OrientationType.Back]: false,
    // [OrientationType.Left]: false,
    // [OrientationType.Right]: false,
  },
  productStyle: productStyleType.TShirt,
  isWovenLabelMenuOpen: false,

  // states from ColorMenu.tsx
  showInfo: false,
  showInfoCustom: false,
  showCostTable: false,
  isSearching: false,
  searchTerm: "",
  filteredPantoneColors: [],

  // states from User Information
  invoicingDetails: cachedInvoicingDetails
    ? {
        firstName: cachedInvoicingDetails.invoice_address.first_name,
        lastName: cachedInvoicingDetails.invoice_address.last_name,
        company: cachedInvoicingDetails.company ? cachedInvoicingDetails.company : "",
        address1: cachedInvoicingDetails.invoice_address.address1,
        address2: cachedInvoicingDetails.invoice_address.address2,
        zip: cachedInvoicingDetails.invoice_address.zip,
        city: cachedInvoicingDetails.invoice_address.city,
        province: cachedInvoicingDetails.invoice_address.province,
        country: cachedInvoicingDetails.invoice_address.country,
        country_code: cachedInvoicingDetails.invoice_address.country_code,
        email: cachedInvoicingDetails.invoice_address.email,
        // phone: cachedInvoicingDetails.invoice_address.phone,
        phone: cachedInvoicingDetails.invoice_address.phone_code + cachedInvoicingDetails.invoice_address.phone,
        phone_code: cachedInvoicingDetails.invoice_address.phone_code,
      }
    : {
        firstName: "",
        lastName: "",
        company: "",
        address1: "",
        address2: "",
        zip: "",
        city: "",
        province: "",
        country: "United states",
        country_code: "US",
        email: "",
        phone: "",
        phone_code: "",
      },

  shippingDetails:
    cachedShippingDetails &&
    cachedShippingSameAsInvoicingFlag !== undefined &&
    cachedShippingSameAsInvoicingFlag == false
      ? {
          firstName: cachedShippingDetails.shipping_address.first_name,
          lastName: cachedShippingDetails.shipping_address.last_name,
          company: cachedShippingDetails.company ? cachedShippingDetails.company : "",
          address1: cachedShippingDetails.shipping_address.address1,
          address2: cachedShippingDetails.shipping_address.address2,
          zip: cachedShippingDetails.shipping_address.zip,
          city: cachedShippingDetails.shipping_address.city,
          province: cachedShippingDetails.shipping_address.province,
          country: cachedShippingDetails.shipping_address.country,
          country_code: cachedShippingDetails.shipping_address.country_code,
          email: cachedShippingDetails.shipping_address.email,
          // phone: cachedShippingDetails.shipping_address.phone,
          phone: cachedShippingDetails.shipping_address.phone_code + cachedShippingDetails.shipping_address.phone,
          phone_code: cachedShippingDetails.shipping_address.phone_code,
        }
      : {
          firstName: "",
          lastName: "",
          company: "",
          address1: "",
          address2: "",
          zip: "",
          city: "",
          province: "",
          country: "United states",
          country_code: "US",
          email: "",
          phone: "",
          phone_code: "",
        },

  // states from ArtworkMenu.tsx
  showInfoGarmentSize: true,
  showInfoUpload: true,
  showDimMapTable: false,
  step: ArtworkSelectionStep.SizeSelection,

  embellishmentChoice: {
    [EmbellishmentType.DigitalPrint]: false,
    [EmbellishmentType.DTF]: false,
    [EmbellishmentType.ScreenPrint]: false,
    [EmbellishmentType.Embroidery]: false,
    [EmbellishmentType.HeatTransfer]: false,
  },

  embellishments: computed(
    [(state) => state.productStyle, (state) => state.embellishmentChoice],
    (productStyle, userChoices) => {
      if (productStyle === productStyleType.Cap) {
        return {
          [EmbellishmentType.Embroidery]: true,
          [EmbellishmentType.DigitalPrint]: false,
          [EmbellishmentType.DTF]: false,
          [EmbellishmentType.ScreenPrint]: false,
          [EmbellishmentType.HeatTransfer]: false,
        };
      } else {
        return userChoices;
      }
    }
  ),

  // selectedView: "front",
  // selectedView: OrientationType.Front,
  selectedView: null,
  uploadedFile: null,
  allowedFileTypes: ["jpg", "jpeg", "png", "ai", "svg", "pdf", "webp"],
  selectedSize: null,
  selectedTechnique: "standard",
  selectedArtworkColor: null,
  errorMessage: null,

  // states from WovenLabelMenu.tsx
  // selectedWovenLabelSize: null,
  // labelImageUrl: null,
  // labelImage: null,
  // selectedWovenLabelPosition: WovenLabelPositionType.BelowNeckTape,
  // wovenLabelArtworkImageUrl: null,
  // wovenLabelArtworkImage: null,
  // selectedWovenLabelStitch: WovenLabelStitchType.TwoCorner,
  wovenLabelSummary: null,

  // Actions
  setConfiguration: action(function setConfiguration(state, payload) {
    state.configuration = payload;
  }),

  resetConfiguration: action(function resetConfiguration(state) {
    state.configuration = null;
  }),

  setCart: action(function setCart(state, payload) {
    state.cart = payload;
    setLocalStorage("cart_id", payload.uuid);
  }),

  resetCart: action(function resetCart(state) {
    removeFromLocalStorage(`cart_id`);
    state.cart = null;
  }),

  setCheckout: action(function setCheckout(state, payload) {
    state.checkout = payload;
  }),

  setOrder: action(function setCheckout(state, payload) {
    state.order = payload;
  }),

  resetCheckout: action(function resetCheckout(state, payload) {
    removeFromLocalStorage("checkout_id");
    removeFromLocalStorage(`stripe_payment_intent`);
    removeFromLocalStorage(`stripe_customer_id`);
    state.checkout = null;
  }),

  setIsCartSidebarExpanded: action(function setIsCartSidebarExpanded(state, payload) {
    state.isCartSidebarExpanded = payload;
  }),

  // actions from Product.tsx
  // setSelectedColor: action(function setSelectedColor(state, payload) {
  //   state.selectedColor = payload;
  // }),

  // setSelectedColorName: action(function setSelectedColorName(state, payload) {
  //   state.selectedColorName = payload;
  // }),

  // setSelectedColorCss: action(function setSelectedColorCss(state, payload) {
  //   state.selectedColorCss = payload;
  // }),

  setColorChanged: action(function setColorChanged(state, payload) {
    state.colorChanged = payload;
  }),

  setIsColorMenuOpen: action(function setIsColorMenuOpen(state, payload) {
    state.isColorMenuOpen = payload;
  }),

  setProductImg: action(function setProductImg(state, payload) {
    state.productImg = payload;
  }),

  setImageDimensions: action(function setImageDimensions(state, payload) {
    state.imageDimensions = payload;
  }),

  setRgbValues: action(function setRgbValues(state, payload) {
    state.rgbValues = payload;
  }),

  // setCurrentOrientation: action(function setCurrentOrientation(state, payload) {
  //   state.currentOrientation = payload;
  // }),

  setCurrentLeadTime: action(function setCurrentLeadTime(state, payload) {
    state.currentLeadTime = payload;
  }),

  setShowLeadTimeInfo: action(function setShowLeadTimeInfo(state, payload) {
    state.showLeadTimeInfo = payload;
  }),

  // setArtworkWidth: action(function setArtworkWidth(state, payload) {
  //   state.artworkWidth = payload;
  // }),

  // setFromNeckSeam: action(function setFromNeckSeam(state, payload) {
  //   state.fromNeckSeam = payload;
  // }),

  // setFromCenter: action(function setFromCenter(state, payload) {
  //   state.fromCenter = payload;
  // }),

  setArtworkColor: action(function setArtworkColor(state, payload) {
    state.artworkColor = payload;
  }),

  setShowGrid: action(function setShowGrid(state, payload) {
    state.showGrid = payload;
  }),

  setShowLeftChest: action(function setShowLeftChest(state, payload) {
    state.showLeftChest = payload;
  }),

  setIsProductLoading: action(function setIsProductLoading(state, payload) {
    state.isProductLoading = payload;
  }),

  setArtworkSummary: action(function setArtworkSummary(state, payload) {
    state.artworkSummary = payload;
    state.artworkDone = !!payload;
  }),

  // setArtworkImageUrl: action(function setArtworkImageUrl(state, payload) {
  //   state.artworkImageUrl = payload;
  // }),

  // setArtworkImage: action(function setArtworkImage(state, payload) {
  //   state.artworkImage = payload;
  // }),

  setSelectedSizeDimensions: action(function setSelectedSizeDimensions(state, payload) {
    state.selectedSizeDimensions = payload;
  }),

  setSelectedSizeDimensionsOriented: action(function setSelectedSizeDimensionsOriented(state, payload) {
    state.selectedSizeDimensionsOriented = payload;
  }),

  setIsArtworkMenuOpen: action(function setIsArtworkMenuOpen(state, payload) {
    state.isArtworkMenuOpen = payload;
  }),

  setArtworkDone: action(function setArtworkDone(state, payload) {
    state.artworkDone = payload;
  }),

  setArtworkDoneOriented: action(function setArtworkDoneOriented(state, payload) {
    state.artworkDoneOriented = payload;
  }),

  setProductStyle: action((state, payload) => {
    state.productStyle = payload;
  }),

  setIsWovenLabelMenuOpen: action((state, payload) => {
    state.isWovenLabelMenuOpen = payload;
  }),

  // actions from ColorMenu.tsx
  setShowInfo: action(function setShowInfo(state, payload) {
    state.showInfo = payload;
  }),

  setShowInfoCustom: action(function setShowInfoCustom(state, payload) {
    state.showInfoCustom = payload;
  }),

  setShowCostTable: action(function setShowCostTable(state, payload) {
    state.showCostTable = payload;
  }),

  setIsSearching: action(function setIsSearching(state, payload) {
    state.isSearching = payload;
  }),

  setSearchTerm: action(function setSearchTerm(state, payload) {
    state.searchTerm = payload;
  }),

  setFilteredPantoneColors: action(function setFilteredPantoneColors(state, payload) {
    state.filteredPantoneColors = payload;
  }),

  // actions from User Information
  setInvoicingDetail: action((state, payload) => {
    state.invoicingDetails[payload.field] = payload.value;
  }),
  setShippingDetail: action((state, payload) => {
    state.shippingDetails[payload.field] = payload.value;
  }),
  setSameAsInvoicing: action((state, sameAsInvoicing) => {
    if (sameAsInvoicing) {
      state.shippingDetails = { ...state.invoicingDetails };
    } else {
      state.shippingDetails = {
        firstName: "",
        lastName: "",
        company: "",
        address1: "",
        address2: "",
        zip: "",
        city: "",
        province: "",
        country: "",
        country_code: "",
        email: "",
        phone: "",
        phone_code: "",
      };
    }
  }),

  // actions from ArtworkMenu.tsx
  setShowInfoGarmentSize: action(function setShowInfoGarmentSize(state, payload) {
    state.showInfoGarmentSize = payload;
  }),

  setShowInfoUpload: action(function setShowInfoUpload(state, payload) {
    state.showInfoUpload = payload;
  }),

  setShowDimMapTable: action(function setShowDimMapTable(state, payload) {
    state.showDimMapTable = payload;
  }),

  setStep: action(function setStep(state, payload) {
    state.step = payload;
  }),

  setEmbellishmentChoice: action((state, payload) => {
    const { type } = payload;
    Object.keys(state.embellishmentChoice).forEach((key) => {
      state.embellishmentChoice[key as EmbellishmentType] = key === type;
    });
  }),

  setSelectedView: action(function setSelectedView(state, payload) {
    state.selectedView = payload;
  }),

  setUploadedFile: action(function setUploadedFile(state, payload) {
    state.uploadedFile = payload;
  }),

  setAllowedFileTypes: action(function setAllowedFileTypes(state, payload) {
    state.allowedFileTypes = payload;
  }),

  setSelectedSize: action(function setSelectedSize(state, payload) {
    state.selectedSize = payload;
  }),

  setSelectedTechnique: action(function setSelectedTechnique(state, payload) {
    state.selectedTechnique = payload;
  }),

  setSelectedArtworkColor: action(function setSelectedArtworkColor(state, payload) {
    state.selectedArtworkColor = payload;
  }),

  setErrorMessage: action(function setErrorMessage(state, payload) {
    state.errorMessage = payload;
  }),

  setWovenLabelSummary: action(function setWovenLabelSummary(state, payload) {
    state.wovenLabelSummary = payload;
  }),

  // Thunks
  createConfigurationThunk: thunk(async function createConfigurationThunk(actions, payload) {
    const configResult = await supabaseUtils.createConfiguration<JoinedConfiguration>(
      payload.configuration,
      "*, products (*)"
    );
    if (configResult.isErr()) {
      return err(configResult.error);
    }
    actions.setConfiguration(configResult.value);
    return ok(configResult.value);
  }),

  fetchConfigurationByUUIDThunk: thunk(async function fetchConfigurationByUUIDThunk(actions, payload) {
    const configResult = await supabaseUtils.getConfigurationByUUID<JoinedConfiguration>({
      uuid: payload.uuid,
      fields: `*, products (*)`,
      tenantId: payload.tenantId,
    });
    if (configResult.isErr()) {
      return err(configResult.error);
    }

    actions.setConfiguration(configResult.value);
    return ok(configResult.value);
  }),

  updateConfigurationThunk: thunk(async function updateConfigurationThunk(actions, payload, { getState }) {
    const itemWithCurrentConfig = find(getState().cart?.line_items, matchesProperty("configurations.id", payload.id));

    const configResult = await supabaseUtils.updateConfiguration<JoinedConfiguration>(
      payload.id,
      payload.updatedConfiguration,
      `*, products (*)`,
      payload.tenantId
    );

    if (configResult.isErr()) {
      return err(configResult.error);
    }
    actions.setConfiguration(configResult.value);

    // If this configuration was attached to a line item, we need to recalculate the item's price
    if (itemWithCurrentConfig && payload.priceModifiers) {
      const itemResult = await actions.updateCartItemThunk({
        lineItemId: itemWithCurrentConfig.id,
        lineItem: {
          price: calculateLineItemPrice(
            itemWithCurrentConfig.configurations.products,
            configResult.value,
            payload.priceModifiers,
            sumBy(itemWithCurrentConfig.size_allocations, "quantity")
            // Sumby(itemWithCurrentConfig.size_allocations)
          ),
          price_details: calculateSingleItemImpact(
            itemWithCurrentConfig.configurations.products,
            configResult.value,
            payload.priceModifiers
          ),
          // price: calculateSingleItemPrice(
          //   itemWithCurrentConfig.configurations.products,
          //   configResult.value,
          //   calculateTotalLineItemQuantity(itemWithCurrentConfig.size_allocations as any)
          // ),
        },
        tenantId: payload.tenantId,
      });

      if (itemResult.isErr()) {
        return err(itemResult.error);
      }
    }

    return configResult;
  }),

  fetchCartThunk: thunk(async function fetchCartThunk(actions, payload) {
    console.log("Fetch cart payload", payload);
    const cartResult = await supabaseUtils.getCarts<JoinedCart>({
      id: payload.id,
      fields: cartQuery,
      tenantId: payload.tenantId,
    });
    if (cartResult.isErr()) {
      return err(cartResult.error);
    }

    const [carts] = cartResult.value;

    const returnedCart = { ...carts[0]};
    console.log({ returnedCart });
    actions.setCart(returnedCart);
    return ok(returnedCart);
  }),

  fetchCartThunkUsingUuid: thunk(async function fetchCartThunkUsingUuid(actions, payload, { getState }) {
    const cartResult = await supabaseUtils.getCarts<JoinedCart>({
      uuid: payload.uuid,
      fields: cartQuery,
      tenantId: getState().tenant?.id, // Why we pass a tenant ID in the query (see cart page) if we don't fetch it here from payload ??
    });
    console.log({ cartResult });
    if (cartResult.isErr()) {
      return err(cartResult.error);
    }
    const [carts] = cartResult.value;

    actions.setCart(carts[0]);
    return ok(carts[0]);
  }),

  createCartThunk: thunk(async function createCartThunk(actions, payload) {
    const result = await supabaseUtils.createCart<JoinedCart>(payload, cartQuery);
    if (result.isOk()) {
      setLocalStorage("cart_id", result.value.uuid);
      return await actions.updateCartTotalThunk(result.value);
    }
    return result;
  }),

  checkDiscountCodeThunk: thunk(async (actions, { cart, discountCode }) => {
    const cartDiscountId = cart.discount_id;
    const tenantId = cart.tenant_id || "";
    if (!cartDiscountId || !discountCode) {
      return err(new Error("No discount ID or code provided"));
    }

    // Fetch the discount code from Supabase
    const discountCodeResult = await supabaseUtils.getDiscountCode(discountCode, tenantId);

    if (discountCodeResult.isErr()) {
      return err(discountCodeResult.error);
    }

    const discountData = discountCodeResult.value;

    // Ensure that you return the expected structure
    return ok(discountData);
  }),

  updateCartThunk: thunk(async function updateCart(actions, payload) {
    const result = await supabaseUtils.updateCart<JoinedCart>(
      payload.id,
      omit(payload.updatedCart, "discounts", "line_items"),
      cartQuery,
      payload.tenantId
    );
    if (result.isOk()) {
      return await actions.updateCartTotalThunk(result.value);
    }
    return result;
  }),
  updateTenantThunk: thunk(async (actions, payload, { getState }) => {
    const { tenantId, updatedFields } = payload;

    const result = await supabaseUtils.updateTenant<AdminTenantWithRoles>(tenantId, updatedFields);

    if (result.isOk()) {
      const tenant = getState().adminTenant;

      actions.setAdminTenant({
        ...(tenant as AdminTenantWithRoles),
        tenants: {
          ...(tenant?.tenants || {}),
          ...updatedFields,
        } as AdminTenantWithRoles["tenants"],
      });

      return result;
    }

    if (result.isErr()) {
      console.error("Error updating tenant:", result.error.message || result.error);
    }

    return result;
  }),

  updateCartTotalThunk: thunk(async function updateCartTotalThunk(actions, payload, { getState }) {
    const tenant = getState().tenant;
    const tenantId = tenant?.id || "";
    const globalTenantModifiersIds = tenant?.price_modifier_ids || [];
    const tenantPriceModifiers = await getPriceModifiersFromIds(tenantId, globalTenantModifiersIds);
    // get rush order price modifier
    const rushOrderModifier = tenantPriceModifiers.filter((modifier) => modifier.name === "rush_order")[0];
    // get chosen rush order option from cart attributes
    const rushOptionAttribute = ((payload.attributes as any as Attribute[]) || [])?.find(
      (attr) => attr.name == "rush_option"
    );
    // get rush order component based on chosen option ID
    const rushComponent = rushOrderModifier?.price_modifiers_components?.find(
      (component) => component.id == rushOptionAttribute?.value
    );
    // calculate rushed data (lead time and subtotal)
    const { leadTime: rushedOrderLeadTime, rushOrderAddon } = getRushedData(
      rushComponent as PriceModifierComponent | undefined,
      payload
    );

    // initially set values as rushed order values
    let updatedSubtotal = calculateCartSubtotal(payload) + rushOrderAddon;
    const shippingFees = payload.shipping_fees || 0;
    let updatedTotal = calculateCartTotalPrice(payload) + rushOrderAddon + shippingFees;
    let updatedLeadTime = rushedOrderLeadTime;
    let updatedAttributes = (payload.attributes as any as Attribute[]) || [];

    // if lead time will be less than 1 calculate normal values and reset rush_option attribute to -1 (no rush)
    // if (updatedLeadTime < 1) {
    //   // reset to fallback values
    //   updatedSubtotal = calculateCartSubtotal(payload);
    //   updatedTotal = calculateCartTotalPrice(payload);
    //   updatedLeadTime = calculateProductionTime(payload.line_items);
    //   // update the rush_option attribute to -1
    //   updatedAttributes = [
    //     ...updatedAttributes.filter((attr) => attr.name !== "rush_option"),
    //     { name: "rush_option", value: -1 },
    //   ];
    // }
    // const subtotal = calculateCartSubtotal(cart);

    // // 1. subtotal price
    // let result = subtotal;

    // 2. discount
    // if (cart.discounts) {
    //   result -= calculateDiscount(subtotal, cart.discounts);
    // }

    const result = await supabaseUtils.updateCart<JoinedCart>(
      payload.id,
      {
        total_price: updatedTotal,
        subtotal_price: updatedSubtotal,
        // shipping_fees: calculateShippingFees(payload),
        lead_time: updatedLeadTime,
        attributes: updatedAttributes,
        discounted_amount: payload.discounts ? calculateDiscount(updatedSubtotal, payload.discounts) : 0,
        // lead_time: calculateProductionTime(payload.line_items),
      },
      cartQuery,
      payload.tenant_id || ""
    );
    if (result.isOk()) {
      actions.setCart({
        ...payload,
        total_price: result.value.total_price,
        subtotal_price: result.value.subtotal_price,
        // shipping_fees: result.value.shipping_fees,
        lead_time: result.value.lead_time,
        attributes: result.value.attributes,
        discounted_amount: payload.discounts ? calculateDiscount(updatedSubtotal, payload.discounts) : 0,
      });
    }
    return result;
  }),

  applyDiscountCodeThunk: thunk(async (actions, { cart, discountCode }) => {
    const tenantId = cart.tenant_id || "";
    const cartResult = await supabaseUtils.applyDiscountCode<JoinedCart>(cart, discountCode, cartQuery, tenantId);

    if (cartResult.isOk()) {
      return await actions.updateCartTotalThunk(cartResult.value);
    }

    return cartResult;
  }),

  createOrUpdateProfileThunk: thunk(async function createOrUpdateProfileThunk(actions, payload) {
    const profileResult = await supabaseUtils.createOrUpdateProfile<Tables<"profiles">>(
      payload.profile,
      payload.tenant_id
    );

    if (profileResult.isErr()) {
      return err(profileResult.error);
    }

    if (profileResult.isOk()) {
      const profileData = profileResult.value;

      // Assuming profileData could be an array (when fetching existing profiles),
      // we should handle that and ensure the return type matches what's expected.
      const profile = Array.isArray(profileData) ? profileData[0] : profileData;

      return ok(profile);
    }

    // If you ever hit this point (though you shouldn't), return an error.
    return err(null);
  }),

  createAddressThunk: thunk(async function createAddressThunk(actions, payload) {
    const addressResult = await supabaseUtils.createAddress<Tables<"addresses">>(payload);

    if (addressResult.isErr()) {
      return err(addressResult.error);
    }

    if (addressResult.isOk()) {
      const addressData = addressResult.value;

      // Assuming addressData could be an array (when fetching existing profiles),
      // we should handle that and ensure the return type matches what's expected.
      const address = Array.isArray(addressData) ? addressData[0] : addressData;

      return ok(address);
    }

    // If you ever hit this point (though you shouldn't), return an error.
    return err(null);
  }),

  createCheckoutThunk: thunk(async function createCheckoutThunk(actions, payload) {
    const checkoutResult = await supabaseUtils.createCheckout<JoinedCheckout>(payload);
    if (checkoutResult.isOk()) {
      setLocalStorage("checkout_id", checkoutResult.value.id);
      actions.setCheckout(checkoutResult.value);
    }
    return checkoutResult;
  }),

  updateCheckoutThunk: thunk(async function updateCheckoutThunk(actions, payload) {
    const checkoutResult = await supabaseUtils.updateCheckout<JoinedCheckout>(payload);
    if (checkoutResult.isOk()) {
      setLocalStorage("checkout_id", checkoutResult.value.id);
      actions.setCheckout(checkoutResult.value);
    }
    return checkoutResult;
  }),

  createOrderThunk: thunk(async function createOrderThunk(actions, payload) {
    const orderResult = await supabaseUtils.createOrder<Tables<"orders">>(payload);
    if (orderResult.isOk()) {
      // setLocalStorage("checkout_id", orderResult.value.id);
      // actions.setCheckout(orderResult.value);
    }
    return orderResult;
  }),

  // fetchLatestOrderThunk: thunk(async function fetchOrderThunk(actions, payload) {
  //   const checkoutResult = await supabaseUtils.getLatestOrder();
  //   if (checkoutResult.isErr()) {
  //     return err(checkoutResult.error);
  //   }
  //   if (checkoutResult.isOk()) {
  //     return ok(checkoutResult.value);
  //   }
  //   return ok(checkoutResult);
  // }),

  fetchCheckoutThunk: thunk(async function fetchCheckoutThunk(actions, payload) {
    const checkoutResult = await supabaseUtils.getCheckoutById<JoinedCheckout>({
      id: payload.id,
      fields: checkoutQuery,
      tenantId: payload.tenantId,
    });
    if (checkoutResult.isErr()) {
      return err(checkoutResult.error);
    }
    if (checkoutResult.isOk()) {
      actions.setCheckout(checkoutResult.value[0]);
      return ok(checkoutResult.value);
    }
    return ok(checkoutResult);
  }),

  fetchCheckoutThunkUsingUuid: thunk(async function fetchCheckoutThunkUsingUuid(actions, payload, { getState }) {
    const checkoutResult = await supabaseUtils.getCheckouts<JoinedCheckout>({
      uuid: payload.uuid,
      fields: checkoutQuery,
      tenantId: getState().tenant?.id,
    });
    console.log({ checkoutResult });
    if (checkoutResult.isErr()) {
      return err(checkoutResult.error);
    }
    const [checkout] = checkoutResult.value[0];
    actions.setCheckout(checkout);
    return ok(checkout);
  }),

  fetchProfileThunk: thunk(async function fetchProfileThunk(actions, payload) {
    const profileResult = await supabaseUtils.getProfileById<Tables<"profiles">>({
      id: payload.id,
      tenantId: payload.tenantId,
    });
    if (profileResult.isErr()) {
      console.log("profileErr", profileResult);
      return err(profileResult.error);
    }
    if (profileResult.isOk()) {
      console.log("profileOk", profileResult);
      return ok(profileResult.value);
    }
    return ok(profileResult);
  }),

  fetchOrderThunk: thunk(async function fetchOrderThunk(actions, payload) {
    const checkoutResult = await supabaseUtils.getOrderById<JoinedOrder>({
      id: payload.id,
      fields: orderQuery,
      tenantId: payload.tenantId,
    });

    if (checkoutResult.isErr()) {
      return err(checkoutResult.error);
    }

    if (checkoutResult.isOk()) {
      actions.setOrder(checkoutResult.value[0]); // Set the first order
      return ok(checkoutResult.value); // Return the full array of orders
    }

    return err(new Error("Unexpected state"));
  }),

  fetchOrderThunkUsingUuid: thunk(async function fetchOrderThunkUsingUuid(actions, payload) {
    const orderResult = await supabaseUtils.getOrderByUuid<JoinedOrder>({
      uuid: payload.uuid,
      tenantId: payload.tenantId,
      fields: orderQuery,
    });
    console.log({ orderResult });
    if (orderResult.isErr()) {
      return err(orderResult.error);
    }
    actions.setOrder(orderResult.value);
    return ok(orderResult.value);
  }),
  // getLatestOrderThunk: thunk(async function fetchOrderThunk(actions, payload) {
  //   const orderResult = await supabaseUtils.createOrder<Tables<"orders">>(payload);
  //   if (orderResult.isOk()) {
  //     // setLocalStorage("checkout_id", orderResult.value.id);
  //     // actions.setCheckout(orderResult.value);
  //   }
  //   return orderResult;
  // }),

  // createOrUpdateProfileThunk: thunk(async function createOrUpdateProfileThunk(actions, payload) {
  //   const profileResult = await supabaseUtils.createProfile<Tables<"profiles">>(payload);

  //   if (profileResult.isErr()) {
  //     return err(profileResult.error);
  //   }
  //   // const result = await supabaseUtils.createAddress(payload);
  //   // if (result.isOk()) {
  //   //   setLocalStorage("cart_id", result.value.id);
  //   //   // actions.setCart(result.value);
  //   // }
  //   if (profileResult.isOk()) {
  //     return ok(profileResult.value);
  //   }

  // }),

  // createAddressThunk: thunk(async function createAddressThunk(actions, payload) {
  //   const addressResult = await supabaseUtils.createAddress(payload);

  //   return addressResult
  //   // const result = await supabaseUtils.createAddress(payload);
  //   // if (result.isOk()) {
  //   //   setLocalStorage("cart_id", result.value.id);
  //   //   // actions.setCart(result.value);
  //   // }
  //   // return ok(result);
  // }),

  // createLineItemThunk: thunk(async function createCartThunk(actions, payload) {
  //   const cart: KoalaInsertCart = {
  //     currency: "USD",
  //     customLineItems: payload.customLineItems,
  //     countryCode: "MN",
  //     deployment: "en-MN",
  //     attributes: [],
  //   };
  //   const result = await koalaApiClient.createCart(cart);
  //   if (result.isOk()) {
  //     console.log("cart", result.value);
  //     setLocalStorage(`koala_cart_id_MN`, result.value.data._id);
  //     actions.setCart(result.value.data);
  //   }
  //   return result;
  // }),

  addCartItemThunk: thunk(async function addCartItemThunk(actions, payload) {
    const result = await supabaseUtils.createLineItem(payload);

    if (result.isErr()) {
      return err(result.error);
    }

    const cartResult = await actions.fetchCartThunk({ id: payload.cart_id, tenantId: payload.tenant_id || "" });
    if (cartResult.isErr()) {
      return err(cartResult.error);
    }

    return await actions.updateCartTotalThunk(cartResult.value);
  }),

  deleteCartItemThunk: thunk(async function deleteCartItemThunk(actions, payload) {
    const result = await supabaseUtils.deleteLineItem(payload.lineItemId, payload.tenantId);

    if (result.isErr()) {
      return err(result.error);
    }

    const cartResult = await actions.fetchCartThunk({ id: payload.cartId, tenantId: payload.tenantId });
    if (cartResult.isErr()) {
      return err(cartResult.error);
    }

    return await actions.updateCartTotalThunk(cartResult.value);
  }),

  updateCartItemThunk: thunk(async function updateCartItemThunk(actions, payload) {
    const result = await supabaseUtils.updateLineItem(payload.lineItemId, payload.lineItem, payload.tenantId);

    if (result.isErr()) {
      return err(result.error);
    }
    const cartResult = await actions.fetchCartThunk({ id: result?.value?.cart_id, tenantId: payload?.tenantId });
    if (cartResult.isErr()) {
      return err(cartResult.error);
    }

    return await actions.updateCartTotalThunk(cartResult.value);
  }),

  updateCartItemsThunk: thunk(async function updateLineItems(actions, payload) {
    for (const lineItem of payload.lineItems) {
      const result = await supabaseUtils.updateLineItem(
        lineItem.id,
        omit(lineItem, "configurations", "total_price", "total_quantity", "index"),
        payload.tenantId
      );

      if (result.isErr()) {
        return err(result.error);
      }
    }
    actions.setCart({ ...payload.cart, line_items: payload.lineItems });

    // const cartResult = await actions.fetchCartThunk(payload.cart.id);

    // if (cartResult.isErr()) {
    //   return err(cartResult.error);
    // }

    return await actions.updateCartTotalThunk({ ...payload.cart, line_items: payload.lineItems });
  }),

  // fetchCheckoutThunk: thunk(async function fetchCheckoutThunk(actions, payload) {
  //   const checkoutResult = await supabaseUtils.getCheckoutById<JoinedCheckout>({ id: payload, fields: checkoutQuery });
  //   if (checkoutResult.isErr()) {
  //     return err(checkoutResult.error);
  //   }
  //   if (checkoutResult.isOk()) {
  //     actions.setCheckout(checkoutResult.value[0]);
  //     return ok(checkoutResult.value);
  //   }
  //   return ok(checkoutResult);
  // }),

  // createAddressThunk:

  // createCheckoutThunk: thunk(async function createCheckoutThunk(actions, payload) {
  //   // const result = await koalaApiClient.createCheckout(payload.cartId, payload.customer);
  //   if (!payload.cart_id) return
  // const checkoutResult = await supabaseUtils.createCheckout(payload.cart_id, payload.customer);
  //   if (checkoutResult.isErr()) {
  //     return err(checkoutResult.error);
  //   }

  //   if (checkoutResult.isOk()) {
  //     // setLocalStorage(`checkout_id_${payload.countryCode}`, checkoutResult.value.data._id);
  //     // actions.setCheckout(checkoutResult.value.data);
  //     return ok(checkoutResult.value);
  //   }

  // }),

  // updateCheckoutCustomerThunk: thunk(async function updateCheckoutCustomerThunk(actions, payload) {
  //   const result = await koalaApiClient.updateCheckoutCustomer(payload.checkoutId, payload.customer);

  //   if (result.isOk()) {
  //     actions.setCheckout(result.value.data);
  //   }
  //   return result;
  // }),
};

export default storeModel;
