export enum ProductStatus {
  draft = "draft",
  enabled = "enabled",
  disabled = "disabled",
}


export enum ProductType {
  tenantsProducts = "tenants",
  templatesProducts = "templates",
}
