"use client";
import { Card } from "@/components/ui/card";
import { createClient } from "@/lib/supabase/client";
import { useStoreState } from "@/hooks/storeHooks";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { format, subDays, eachDayOfInterval } from "date-fns";
import { Loader2 } from "lucide-react";
import useSWR from "swr";
import { Skeleton } from "@/components/ui/skeleton";
import Restricted from "../../../components/Restricted";
import { formatCurrency } from "../../../lib/utils/n";

interface DashboardMetrics {
  totalRevenue: number;
  totalOrders: number;
  ordersToAcknowledge: number;
  ordersInProgress: number;
}

interface DailyRevenue {
  date: string;
  revenue: number;
}

const formatAxisCurrency = (value: number): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

async function fetchDashboardData(key: string, tenant_id: string, startDate: Date, endDate: Date) {
  if (!tenant_id) return null;

  const supabase = createClient();

  // Generate all dates in the range
  const dateRange = eachDayOfInterval({ start: startDate, end: endDate });
  const emptyDateData = dateRange.reduce((acc, date) => {
    acc[format(date, "MMM dd")] = 0;
    return acc;
  }, {} as { [key: string]: number });

  // Fetch all required data in parallel
  const [ordersResponse, paymentLinksResponse] = await Promise.all([
    // Orders from last 30 days
    supabase
      .from("orders")
      .select("created_at, total_price, status, production_status")
      .eq("tenant_id", tenant_id)
      .eq("status", "paid")
      .gte("created_at", startDate.toISOString())
      .lte("created_at", endDate.toISOString())
      .order("created_at"),

    // Payment links from last 30 days
    supabase
      .from("payment_links")
      .select("created_at, amount")
      .eq("tenant_id", tenant_id)
      .eq("status", "paid")
      .gte("created_at", startDate.toISOString())
      .lte("created_at", endDate.toISOString())
      .order("created_at"),
  ]);

  const orders = ordersResponse.data || [];
  const paymentLinks = paymentLinksResponse.data || [];

  // Calculate metrics for last 30 days
  const metrics: DashboardMetrics = {
    totalRevenue:
      orders.reduce((sum, order) => sum + (order.total_price || 0), 0) +
      paymentLinks.reduce((sum, link) => sum + (link.amount || 0), 0),
    totalOrders: orders.length,
    ordersToAcknowledge: orders.filter((o) => o.production_status === null).length,
    ordersInProgress: orders.filter((o) => o.production_status === "processing").length,
  };

  // Process daily revenue, starting with empty dates
  const dailyRevenue = { ...emptyDateData };

  // Add orders revenue
  orders.forEach((order) => {
    const date = format(new Date(order.created_at), "MMM dd");
    dailyRevenue[date] = (dailyRevenue[date] || 0) + (order.total_price || 0);
  });

  // Add payment links revenue
  paymentLinks.forEach((link) => {
    const date = format(new Date(link.created_at || ""), "MMM dd");
    dailyRevenue[date] = (dailyRevenue[date] || 0) + (link.amount || 0);
  });

  const revenueData = Object.entries(dailyRevenue).map(([date, revenue]) => ({
    date,
    revenue,
  }));

  return {
    metrics,
    revenueData,
  };
}

export default function DashboardPage() {
  const tenant = useStoreState((s) => s.adminTenant);

  const endDate = new Date();
  const startDate = subDays(endDate, 30);

  const { data, error, isLoading } = useSWR(
    tenant && tenant.tenants ? ["dashboard", tenant.tenants?.id] : null,
    () => fetchDashboardData("dashboard", tenant?.tenants?.id || "", startDate, endDate),
    {
      refreshInterval: 30000,
      revalidateOnFocus: true,
    }
  );

  if (error) {
    return (
      <div className="p-8">
        <div className="text-red-500">Error loading dashboard data</div>
      </div>
    );
  }

  return (
    <>
      <Restricted roleName="dashboard.view" invisible={false}>
        <div className="p-8 space-y-8">
          <h1 className="">Dashboard</h1>

          <div className="grid grid-cols-4 gap-4">
            {isLoading ? (
              <>
                <MetricCardSkeleton />
                <MetricCardSkeleton />
                <MetricCardSkeleton />
                <MetricCardSkeleton />
              </>
            ) : (
              <>
                <MetricCard
                  title="Total Revenue"
                  subtitle="Last 30 days"
                  value={formatCurrency(data?.metrics.totalRevenue || 0)}
                />
                <MetricCard title="Total Orders" value={data?.metrics.totalOrders.toString() || "0"} />
                <MetricCard title="Orders to Acknowledge" value={data?.metrics.ordersToAcknowledge.toString() || "0"} />
                <MetricCard title="Orders in Progress" value={data?.metrics.ordersInProgress.toString() || "0"} />
              </>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <Card className="p-6 col-span-1">
              <h2 className="text-lg font-semibold mb-4">Revenue Last 30 Days</h2>
              <div className="h-[300px]">
                {isLoading ? (
                  <ChartSkeleton />
                ) : (
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={data?.revenueData || []}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" tick={{ fontSize: 12 }} tickMargin={8} />
                      <YAxis
                        tickFormatter={(value) => formatAxisCurrency(value)}
                        tick={{ fontSize: 12 }}
                        tickMargin={8}
                      />
                      <Tooltip
                        formatter={(value: number) => [formatCurrency(value), "Revenue"]}
                        contentStyle={{
                          backgroundColor: "rgba(255, 255, 255, 0.9)",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          fontSize: "12px",
                        }}
                        labelStyle={{ fontSize: "12px" }}
                      />
                      <defs>
                        <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                          <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                      <Area
                        type="monotone"
                        dataKey="revenue"
                        stroke="#8884d8"
                        strokeWidth={2}
                        fillOpacity={1}
                        fill="url(#colorRevenue)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                )}
              </div>
            </Card>
          </div>
        </div>
      </Restricted>
    </>
  );
}

function MetricCard({ title, subtitle, value }: { title: string; subtitle?: string; value: string }) {
  return (
    <Card className="p-6">
      <h3 className="text-sm font-medium text-gray-500">{title}</h3>
      <p className="text-2xl font-bold mt-2">{value}</p>
      {subtitle && <p className="text-xs text-muted-foreground">{subtitle}</p>}
    </Card>
  );
}

function MetricCardSkeleton() {
  return (
    <Card className="p-6">
      <Skeleton className="h-4 w-24 mb-3" />
      <Skeleton className="h-8 w-32" />
    </Card>
  );
}

function ChartSkeleton() {
  return (
    <div className="w-full h-full flex flex-col gap-2">
      <div className="flex justify-between items-end h-full">
        {/* Create bars with different heights */}
        {[...Array(12)].map((_, i) => (
          <Skeleton
            key={i}
            className="w-8"
            style={{
              height: `${Math.max(20, Math.random() * 100)}%`,
            }}
          />
        ))}
      </div>
      {/* X-axis line */}
      <Skeleton className="h-1 w-full mt-2" />
    </div>
  );
}
